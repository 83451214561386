﻿export const ARRAY_INDEX_NOT_FOUND: number = -1;
export const TIME_TO_WAIT_FOR_INPUT_ms: number = 200;

export enum NumberPrefixNames {
    YOTTA = 1000000000000000000000000,
    ZETTA = 1000000000000000000000,
    EXA = 1000000000000000000,
    PETA = 1000000000000000,
    TERA = 1000000000000,
    GIGA = 1000000000,
    MEGA = 1000000,
    KILO = 1000,
    MILLI = 0.001,
    MICRO = 0.000001,
    NANO = 0.000000001,
    PICO = 0.000000000001,
    FEMTO = 0.000000000000001,
    ATTO = 0.000000000000000001,
    ZEPTO = 0.000000000000000000001,
    YOCTO = 0.000000000000000000000001
};