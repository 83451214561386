﻿import { Subject } from "rxjs";
import { Subscription } from "rxjs";

export interface ITranslationService {
    state: Subject<any>;
    data: {};
    currentLanguage: any;
    setLanguage(language: any): void;
    subscribe(subscription: any, error: any): Subscription;
    getTranslation(phrase: string): string;
}

export abstract class TranslationService implements ITranslationService{
    abstract state: Subject<any>;
    abstract data: {};
    abstract currentLanguage: any;
    abstract setLanguage: (language: any) => void;
    abstract subscribe: (subscription: any, error: any) => Subscription;
    abstract getTranslation: (phrase: string) => string;
}