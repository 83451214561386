import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LocationModel, AppConfigurationService, CreateLocationResponse, DeleteLocationResponse, GetLocationResponse, GetListLocationsResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchLocationResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class LocationService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createLocation = new HttpRequestStream<LocationModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newLocation$ = this.m_createLocation.response$;
    public isCreateLocationLoading$ = this.m_createLocation.isLoading$;

    private m_getLocations = new HttpPaginatedRequestStream<LocationModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public locations$ = this.m_getLocations.response$;
    public isLocationsLoading$ = this.m_getLocations.isLoading$;

    private m_getLocation = new HttpRequestStream<LocationModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public location$ = this.m_getLocation.response$;
    public isLocationLoading$ = this.m_getLocation.isLoading$;

    private m_patchLocation = new HttpRequestStream<LocationModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchLocation$ = this.m_patchLocation.response$;
    public isPatchLocationLoading$ = this.m_patchLocation.isLoading$;

    private m_updateLocation = new HttpRequestStream<LocationModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateLocation$ = this.m_updateLocation.response$;
    public isUpdateLocationLoading$ = this.m_updateLocation.isLoading$;

    private m_deleteLocation = new HttpRequestStream<DeleteLocationResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteLocation$ = this.m_deleteLocation.response$;
    public isDeleteLocationLoading$ = this.m_deleteLocation.isLoading$;

    private m_deleteLocations = new HttpRequestStream<DeleteLocationResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteLocations$ = this.m_deleteLocations.response$;
    public isDeleteLocationsLoading$ = this.m_deleteLocations.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createLocation(location: LocationModel): Observable<CreateLocationResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Location/`;
        return this.m_createLocation.send({ url: url, method: HttpMethod.POST, body: location });
    }

    public getLocations(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListLocationsResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Location/${getPaginatedRequestUrl(options)}`;
        return this.m_getLocations.send({ url: url, isPoll: isPoll });
    }

    public getLocation(id: number, isPoll = false): Observable<GetLocationResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Location/${id}/`;
        return this.m_getLocation.send({ url: url, isPoll: isPoll });
    }

    public patchLocation(id: number, obj: any): Observable<PatchLocationResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Location/${id}/`;
        return this.m_patchLocation.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public updateLocation(id: number, obj: any): Observable<PatchLocationResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Location/${id}/`;
        return this.m_updateLocation.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public deleteLocation(id: number): Observable<DeleteLocationResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Location/${id}/`;
        return this.m_deleteLocation.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteLocations(ids: Array<number>): Observable<DeleteLocationResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Location/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteLocations.send({ url: url, method: HttpMethod.DELETE });
    }
}
