
import { PaginatedResponseModel } from "../paginated-response.model";
import { ReleaseNoteModel } from "./release-note.model";

export class ReleaseModel {
    public id: number;
    public version: string;
    public name: string;
    public description: string;
    public released: Date;
    public notes: Array<any>;

    //constructor();
    constructor(release?: ReleaseModel) {
        this.id = release && release.id || 0;
        this.version = release && release.version || '';
        this.name = release && release.name || '';
        this.description = release && release.description || '';
        this.released = release && release.released || undefined;
        this.notes = release && release.notes || [];
    }
}


export class GetListReleasesResponse extends PaginatedResponseModel<ReleaseModel> {
    
}

export class GetReleaseNotesResponse extends Array<ReleaseNoteModel> {

}
