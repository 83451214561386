import { PaginatedResponseModel } from "../paginated-response.model";

export class ProductClassField {
    id: number;
    required: boolean;
    classId: number;
    name: string;
    type: string;
}

export class ProductClass {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    name: string;
    description: string;
    type: string;
    requiredParents: Array<ProductClass> = [];
    requiredChildren: Array<ProductClass> = [];
    allowedChildren: Array<ProductClass> = [];
    identifierFields: Array<ProductClassField> = [];
}

export class GetListProductClassesResponse extends PaginatedResponseModel<ProductClass> { }
export class GetProductClassResponse extends ProductClass { }
export class CreateProductClassResponse extends ProductClass { }
export class PatchProductClassResponse extends ProductClass { }
export class DeleteProductClassResponse { } 