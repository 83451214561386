import { Component, Input } from '@angular/core';
import { ContactModel } from '../../models';

@Component({
    selector: 'ph-contact',
    templateUrl: './contact.component.html',
})
export class ContactComponent {
    @Input() public contact: ContactModel = null;

    private m_ssNumber = true;
    @Input()
    set ssNumber(value: boolean) {
        if (value != null) {
            this.m_ssNumber = value;
        } else {
            this.m_ssNumber = true;
        }
    }
    get ssNumber(): boolean {
        return this.m_ssNumber;
    }
}
