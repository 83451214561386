import { PaginatedResponseModel } from "../paginated-response.model";
import { CountryModel } from "./country.model";

export class ContactEmailModel {
    public description: string;
    public emailAddress: string;
}

export class ContactDateModel {
    public date: Date;
    public time: Date;
    public description: string;
    public icon: string;
}

export class ContactModel {
    public id: number;
    public createdDate: Date;
    public modifiedDate: Date;
    public namePrefix: string;
    public nameSuffix: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public companyName: string;
    public address_1: string;
    public address_2: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public countryId: number;
    public country: CountryModel;
    public phoneNumber: string;
    public faxNumber: string;
    public socialSecurityNumber: string;
    public emails: Array<ContactEmailModel> = [];
    public dates: Array<ContactDateModel>;
    public obsolete: boolean;
}

export class GetListContactsResponse extends PaginatedResponseModel<ContactModel> { }
// export class GetListContactsResponse extends Array<ContactModel> { }
export class GetContactResponse extends ContactModel { } 
export class CreateContactResponse extends ContactModel { } 
export class PatchContactResponse extends ContactModel { } 
export class DeleteContactResponse { } 