import { PaginatedResponseModel } from "../paginated-response.model";
import { CountryModel } from "./country.model";
import { UsageType } from "./usage.model";

export class AllowancePool {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    amount: number;
    description: string;
    ratePlanId: number;
    rateGroupRates: Array<RateGroupRate> = [];
}

export class FreeMeasurePool {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    amount: number;
    description: string;
    ratePlanId: number;
    rateGroupRates: Array<RateGroupRate> = [];
}

export class RateGroup {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    name: string;
    ratePlanId: number;
    ratePlan: RatePlanModel;
    fromZones: boolean = true;
    toZones: boolean = true;
    type: UsageType;
    subType: string;
    direction: UsageDirection;
    rates: Array<RateGroupRate> = [];
}

export class RateGroupRate {
    id: number;
    rateGroupId: number;
    rate: number;
    allowancePoolRate: number;
    fromZoneId: number | null;
    fromZone?: RatePlanZone;
    toZoneId: number | null;
    toZone?: RatePlanZone;
    freeMeasurePoolId: number | null;
    freeMeasurePool?: FreeMeasurePool;
    allowancePoolId: number | null;
    allowancePool?: AllowancePool;
}

export class RatePlanZone {
    id: number;
    createDate: Date;
    modifiedDate: Date;
    ratePlanId: number;
    ratePlan: RatePlanModel;
    name: string;
    countries: Array<CountryModel> = [];
}

export class RatePlanModel {
    id: number;
    name: string;
    allowancePools: Array<AllowancePool> = [];
    createdDate: Date;
    description: string;
    freeMeasurePools: Array<FreeMeasurePool> = [];
    modifiedDate: Date;
    rateGroups: Array<RateGroup> = [];
    zones: Array<RatePlanZone> = [];
}

export enum UsageDirection {
    UNKOWN
    , INCOMING
    , OUTGOING
}

export class GetListRatePlansResponse extends PaginatedResponseModel<RatePlanModel> { }
export class GetRatePlanResponse extends RatePlanModel { }
export class CreateRatePlanResponse extends RatePlanModel { }
export class PatchRatePlanResponse extends RatePlanModel { }
export class DeleteRatePlanResponse { } 