import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { RatePlanModel, AppConfigurationService, CreateRatePlanResponse, DeleteRatePlanResponse, GetRatePlanResponse, GetListRatePlansResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchRatePlanResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class RatePlanService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createRateplan = new HttpRequestStream<RatePlanModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newRateplan$ = this.m_createRateplan.response$;
    public isCreateRateplanLoading$ = this.m_createRateplan.isLoading$;

    private m_getRateplans = new HttpPaginatedRequestStream<RatePlanModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public rateplans$ = this.m_getRateplans.response$;
    public isRateplansLoading$ = this.m_getRateplans.isLoading$;

    private m_getRateplan = new HttpRequestStream<RatePlanModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public rateplan$ = this.m_getRateplan.response$;
    public isRateplanLoading$ = this.m_getRateplan.isLoading$;

    private m_patchRateplan = new HttpRequestStream<RatePlanModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchRateplan$ = this.m_patchRateplan.response$;
    public isPatchRateplanLoading$ = this.m_patchRateplan.isLoading$;

    private m_updateRatePlan = new HttpRequestStream<RatePlanModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateRatePlan$ = this.m_updateRatePlan.response$;
    public isUpdateRatePlanLoading$ = this.m_updateRatePlan.isLoading$;

    private m_deleteRateplan = new HttpRequestStream<DeleteRatePlanResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteRateplan$ = this.m_deleteRateplan.response$;
    public isDeleteRateplanLoading$ = this.m_deleteRateplan.isLoading$;

    private m_deleteRateplans = new HttpRequestStream<DeleteRatePlanResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteRateplans$ = this.m_deleteRateplans.response$;
    public isDeleteRateplansLoading$ = this.m_deleteRateplans.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createRateplan(rateplan: RatePlanModel): Observable<CreateRatePlanResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/RatePlan/`;
        return this.m_createRateplan.send({ url: url, method: HttpMethod.POST, body: rateplan });
    }

    public getRateplans(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListRatePlansResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/RatePlan/${getPaginatedRequestUrl(options)}`;
        return this.m_getRateplans.send({ url: url, isPoll: isPoll });
    }

    public getRateplan(id: number, isPoll = false): Observable<GetRatePlanResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/RatePlan/${id}/`;
        return this.m_getRateplan.send({ url: url, isPoll: isPoll });
    }

    public updateRatePlan(id: number, obj: any): Observable<PatchRatePlanResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/RatePlan/${id}/`;
        return this.m_updateRatePlan.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public patchRateplan(id: number, obj: any): Observable<PatchRatePlanResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/RatePlan/${id}/`;
        return this.m_patchRateplan.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteRateplan(id: number): Observable<DeleteRatePlanResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/RatePlan/${id}/`;
        return this.m_deleteRateplan.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteRateplans(ids: Array<number>): Observable<DeleteRatePlanResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/RatePlan/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteRateplans.send({ url: url, method: HttpMethod.DELETE });
    }
}
