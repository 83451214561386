import { Component } from '@angular/core';
import { AppConfigurationService } from './ultisat/models';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  title = 'Pharaoh 2.0';

  /**
   * 
   * @param envConfigSvc Initialize the application cofiguration service
   */
  constructor(envConfigSvc: AppConfigurationService) { }
}
