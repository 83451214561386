import { Component, OnInit } from '@angular/core';
import { ReleaseNotesComponent } from '@app/ultisat/components';
import { ReleaseModel } from '@app/ultisat/models';
import { ReleaseNotesService } from '@app/ultisat/services';
import { BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  private m_ngUnsubscribe = new Subject();
  public release: ReleaseModel;
  date: Date;
  constructor(public bsModalService: BsModalService, private m_releaseNotesSvc: ReleaseNotesService) { }

  ngOnInit() {
    this.date = new Date();
    this.m_releaseNotesSvc.getReleasesPage();
    this.m_releaseNotesSvc.releases$.pipe(takeUntil(this.m_ngUnsubscribe)).subscribe(response => {
      if (response && response.results && response.results.length) {
        this.release = response.results[0];
      }
    });
  }

  ngOnDestroy(): void {
    this.m_ngUnsubscribe.next();
    this.m_ngUnsubscribe.complete();
  }

  openReleaseNotes() {
    this.bsModalService.show(ReleaseNotesComponent);
  }
}
