import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Account, AppConfigurationService, CreateAccountResponse, DeleteAccountResponse, GetAccountResponse, GetListAccountsResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchAccountResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class AccountService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createAccount = new HttpRequestStream<Account>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newAccount$ = this.m_createAccount.response$;
    public isCreateAccountLoading$ = this.m_createAccount.isLoading$;

    private m_getAccounts = new HttpPaginatedRequestStream<Account>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public accounts$ = this.m_getAccounts.response$;
    public isAccountsLoading$ = this.m_getAccounts.isLoading$;

    private m_getAccount = new HttpRequestStream<Account>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public account$ = this.m_getAccount.response$;
    public isAccountLoading$ = this.m_getAccount.isLoading$;

    private m_patchAccount = new HttpRequestStream<Account>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchAccount$ = this.m_patchAccount.response$;
    public isPatchAccountLoading$ = this.m_patchAccount.isLoading$;

    private m_updateAccount = new HttpRequestStream<Account>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateAccount$ = this.m_updateAccount.response$;
    public isUpdateAccountLoading$ = this.m_updateAccount.isLoading$;

    private m_deleteAccount = new HttpRequestStream<DeleteAccountResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteAccount$ = this.m_deleteAccount.response$;
    public isDeleteAccountLoading$ = this.m_deleteAccount.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createAccount(account: Account): Observable<CreateAccountResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/`;
        return this.m_createAccount.send({ url: url, method: HttpMethod.POST, body: account });
    }

    public getAccounts(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListAccountsResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${getPaginatedRequestUrl(options)}`;
        return this.m_getAccounts.send({ url: url, isPoll: isPoll });
    }

    public getAccount(id: number, isPoll = false): Observable<GetAccountResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${id}/`;
        return this.m_getAccount.send({ url: url, isPoll: isPoll });
    }

    public updateAccount(id: number, obj: any): Observable<PatchAccountResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${id}/`;
        return this.m_updateAccount.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public patchAccount(id: number, obj: any): Observable<PatchAccountResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${id}/`;
        return this.m_patchAccount.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteAccount(id: number): Observable<DeleteAccountResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${id}/`;
        return this.m_deleteAccount.send({ url: url, method: HttpMethod.PATCH });
    }    
}
