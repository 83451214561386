import { PaginatedResponseModel } from "../paginated-response.model";
import { ContactModel } from "./contact.model";
import { NumberModel } from "./number.model";
import { PaymentModel } from "./payment.model";
import { TierModel } from "./tier.model";
import { UserRoleModel } from "./user-role.model";
import { UserCapabilityModel } from "./usercapability.model";

export class AccountSettings {
    billingMethod: string;
    clientType: string;
    cycle: string;
    lineOfBusiness: string;
    paperlessBilling: boolean = false;
    autoPay: boolean = false;
    paymentTerms: number;
}

export class AccountContact {
    id: number;
    description: string;
    contactId: number;
    contact: ContactModel;
}

export class Account {
    id: number;
    name: string;
    parent: Account;
    parentId: number;
    userRole: UserRoleModel;
    userRoleId: number;
    settings: AccountSettings = new AccountSettings();
    billing: ContactModel = new ContactModel();
    billingId: number;
    shipping: ContactModel = new ContactModel();
    shippingId: number;
    balance: number = 0;
    createdDate: Date;
    modifiedDate: Date;
    nextChargePeriod: string;
    legacyAccountNumber: string;
    tiers: Array<TierModel> = [];
    numbers: Array<NumberModel> = [];
    payments: Array<PaymentModel> = [];
    capabilities: Array<UserCapabilityModel> = [];
    contacts: Array<AccountContact> = [];
}

export enum ClientType{
    UNKNOWN
    , RENTAL
    , GCB2B
    , GEMS
    , M2M
    , TC
}

export enum BillingMethod {
    UNKNOWN
    , ADVANCE
    , ARREAR
}

export enum LineOfBusiness {
    UNKNOWN
    , COMMERCIAL
    , GOVERNMENTAL
}

export class GetListAccountsResponse extends PaginatedResponseModel<Account> { }
export class GetAccountResponse extends Account { } 
export class CreateAccountResponse extends Account { } 
export class PatchAccountResponse extends Account { } 
export class DeleteAccountResponse { } 