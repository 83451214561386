import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CountryModel, AppConfigurationService, CreateCountryResponse, DeleteCountryResponse, GetCountryResponse, GetListCountriesResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchCountryResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class CountryService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createCountry = new HttpRequestStream<CountryModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newCountry$ = this.m_createCountry.response$;
    public isCreateCountryLoading$ = this.m_createCountry.isLoading$;

    private m_getCountries = new HttpPaginatedRequestStream<CountryModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public countries$ = this.m_getCountries.response$;
    public isCountriesLoading$ = this.m_getCountries.isLoading$;

    private m_getCountry = new HttpRequestStream<CountryModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public country$ = this.m_getCountry.response$;
    public isCountryLoading$ = this.m_getCountry.isLoading$;

    private m_patchCountry = new HttpRequestStream<CountryModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchCountry$ = this.m_patchCountry.response$;
    public isPatchCountryLoading$ = this.m_patchCountry.isLoading$;

    private m_updateCountry = new HttpRequestStream<CountryModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateCountry$ = this.m_updateCountry.response$;
    public isUpdateCountryLoading$ = this.m_updateCountry.isLoading$;

    private m_deleteCountry = new HttpRequestStream<DeleteCountryResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteCountry$ = this.m_deleteCountry.response$;
    public isDeleteCountryLoading$ = this.m_deleteCountry.isLoading$;

    private m_deleteCountries = new HttpRequestStream<DeleteCountryResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteCountries$ = this.m_deleteCountries.response$;
    public isDeleteCountriesLoading$ = this.m_deleteCountries.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createCountry(country: CountryModel): Observable<CreateCountryResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Country/`;
        return this.m_createCountry.send({ url: url, method: HttpMethod.POST, body: country });
    }

    public getCountries(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListCountriesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Country/${getPaginatedRequestUrl(options)}`;
        return this.m_getCountries.send({ url: url, isPoll: isPoll });
    }

    public getCountry(id: number, isPoll = false): Observable<GetCountryResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Country/${id}/`;
        return this.m_getCountry.send({ url: url, isPoll: isPoll });
    }

    public patchCountry(id: number, obj: any): Observable<PatchCountryResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Country/${id}/`;
        return this.m_patchCountry.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public updateCountry(id: number, obj: any): Observable<PatchCountryResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Country/${id}/`;
        return this.m_updateCountry.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public deleteCountry(id: number): Observable<DeleteCountryResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Country/${id}/`;
        return this.m_deleteCountry.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteCountries(ids: Array<number>): Observable<DeleteCountryResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Country/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteCountries.send({ url: url, method: HttpMethod.DELETE });
    }
}
