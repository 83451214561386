import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, timer } from "rxjs";
import { JobInfo, AppConfigurationService, CreateJobInfoResponse, DeleteJobInfoResponse, GetJobInfoResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchJobInfoResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";
import { takeUntil } from "rxjs/operators";


@Injectable()
export class JobInfoService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();
    private readonly POLLING_TIME_ms = 1500;

    private m_getJobInfo = new HttpRequestStream<JobInfo>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public jobInfo$ = this.m_getJobInfo.response$;
    public isJobInfoLoading$ = this.m_getJobInfo.isLoading$;

    private m_getJobInfoQueue = new HttpRequestStream<JobInfo>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public jobInfoQueue$ = this.m_getJobInfoQueue.response$;
    public isJobInfoQueueLoading$ = this.m_getJobInfoQueue.isLoading$;
    private m_stopTimer = new Subject();
    private m_jobInfoIdQueue = new Array<number>();
    private m_currentJobIndex = 0;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public getJobInfo(id: number, isPoll = false): Observable<GetJobInfoResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Job/${id}/`;
        return this.m_getJobInfo.send({ url: url, isPoll: isPoll });
    }

    private startPolling() {
        timer(0, this.POLLING_TIME_ms)
            .pipe(
                takeUntil(this.m_ngUnsubscribe),
                takeUntil(this.m_stopTimer)
            ).subscribe(() => {
                if (this.m_jobInfoIdQueue.length) {
                    const next = this.m_jobInfoIdQueue[this.m_currentJobIndex];
                    const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Job/${next}/`;
                    this.m_getJobInfoQueue.send({ url: url, isPoll: true });
                    ++this.m_currentJobIndex;
                    if (this.m_currentJobIndex >= this.m_jobInfoIdQueue.length)
                    {
                        this.m_currentJobIndex = 0;
                    }
                }
            });
    }
    private stopPolling() {
        this.m_stopTimer.next();
    }

    public addJobToPollQueue(id: number): Observable<GetJobInfoResponse> {
        this.m_jobInfoIdQueue.push(id);
        if (this.m_jobInfoIdQueue.length == 1) {
            this.startPolling();
        }
        return this.jobInfoQueue$;
    }

    public removeJobFromPollQueue(id: number): void {
        const index = this.m_jobInfoIdQueue.findIndex((item) => { return item == id; });
        if (index >= 0) {
            this.m_jobInfoIdQueue.splice(index, 1);
            if (this.m_currentJobIndex >= this.m_jobInfoIdQueue.length) {
                this.m_currentJobIndex = this.m_jobInfoIdQueue.length - 1;
            }
        }
        if (this.m_jobInfoIdQueue.length <= 0) {
            this.stopPolling();
        }
    }
}
