import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { VendorModel, AppConfigurationService, CreateVendorResponse, DeleteVendorResponse, GetVendorResponse, GetListVendorsResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchVendorResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class VendorService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createVendor = new HttpRequestStream<VendorModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newVendor$ = this.m_createVendor.response$;
    public isCreateVendorLoading$ = this.m_createVendor.isLoading$;

    private m_getVendors = new HttpPaginatedRequestStream<VendorModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public vendors$ = this.m_getVendors.response$;
    public isVendorsLoading$ = this.m_getVendors.isLoading$;

    private m_getVendor = new HttpRequestStream<VendorModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public vendor$ = this.m_getVendor.response$;
    public isVendorLoading$ = this.m_getVendor.isLoading$;

    private m_patchVendor = new HttpRequestStream<VendorModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchVendor$ = this.m_patchVendor.response$;
    public isPatchVendorLoading$ = this.m_patchVendor.isLoading$;

    private m_updateVendor = new HttpRequestStream<VendorModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateVendor$ = this.m_updateVendor.response$;
    public isUpdateVendorLoading$ = this.m_updateVendor.isLoading$;

    private m_deleteVendor = new HttpRequestStream<DeleteVendorResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteVendor$ = this.m_deleteVendor.response$;
    public isDeleteVendorLoading$ = this.m_deleteVendor.isLoading$;

    private m_deleteVendors = new HttpRequestStream<DeleteVendorResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteVendors$ = this.m_deleteVendors.response$;
    public isDeleteVendorsLoading$ = this.m_deleteVendors.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createVendor(vendor: VendorModel): Observable<CreateVendorResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Vendor/`;
        return this.m_createVendor.send({ url: url, method: HttpMethod.POST, body: vendor });
    }

    public getVendors(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListVendorsResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Vendor/${getPaginatedRequestUrl(options)}`;
        return this.m_getVendors.send({ url: url, isPoll: isPoll });
    }

    public getVendor(id: number, isPoll = false): Observable<GetVendorResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Vendor/${id}/`;
        return this.m_getVendor.send({ url: url, isPoll: isPoll });
    }

    public patchVendor(id: number, obj: any): Observable<PatchVendorResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Vendor/${id}/`;
        return this.m_patchVendor.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public updateVendor(id: number, obj: any): Observable<PatchVendorResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Vendor/${id}/`;
        return this.m_updateVendor.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public deleteVendor(id: number): Observable<DeleteVendorResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Vendor/${id}/`;
        return this.m_deleteVendor.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteVendors(ids: Array<number>): Observable<DeleteVendorResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Vendor/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteVendors.send({ url: url, method: HttpMethod.DELETE });
    }
}
