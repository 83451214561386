import { PaginatedResponseModel } from "../paginated-response.model";
import { ProductClass } from "./product-class.model";
import { TierModel } from "./tier.model";
import { VendorModel } from "./vendor.model";

export class ProductAvailabilityCost {
    id: number;
    tierId: number;
    tier: TierModel;
    cost: number;
    frequency: string;
}

export class Product {
    id: number;
    createdDate?: Date;
    modifiedDate?: Date;
    name: string;
    description: string;
    type?: ProductTypes;
    classId?: number;
    class?: ProductClass;
    manufacturerId?: number;
    manufacturer?: VendorModel;
    model?: string;
    availability?: Array<ProductAvailabilityCost> = [];
}

export enum ProductAvailabilityFrequency {
    ONE_TIME = 0
    , MONTHLY = 1
    , QUARTERLY = 3
    , ANNUALLY = 12
}

export enum ProductTypes{
    UNKNOWN = 0
    , PHYSICAL = 1
    , VIRTUAL = 2
}

export class ProductPricing {
    id: number;
    name: string;
    frequency: ProductAvailabilityFrequency;
    price: number;
    tierId: number;
    tier: TierModel;
    description: string;

    allowedChildren: Array<ProductPricing>;
    requiredChildren: Array<ProductPricing>;
}

export class GetListProductsResponse extends PaginatedResponseModel<Product> { }
export class GetProductResponse extends Product { } 
export class CreateProductResponse extends Product { } 
export class PatchProductResponse extends Product { } 
export class DeleteProductResponse { } 
export class GetListProductPricingResponse extends PaginatedResponseModel<ProductPricing> { }