import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";
import { UserModel } from "./user.model";
import { UserCapabilityModel } from "./usercapability.model";

export class UserRoleModel {
    id: number;
    name: string;
    description: string;
    parentId: number;
    ownerId: number;
    users: Array<UserModel> = [];
    accounts: Array<Account> = [];
    capabilities?: Array<UserCapabilityModel> = [];
}

export class GetListUserRolesResponse extends PaginatedResponseModel<UserRoleModel> { }
export class GetUserRoleResponse extends UserRoleModel { } 
export class CreateUserRoleResponse extends UserRoleModel { } 
export class PatchUserRoleResponse extends UserRoleModel { } 
export class DeleteUserRoleResponse { } 