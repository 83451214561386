import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AppConfigurationService, GetListUserCapabilitiesResponse, IPaginatedRequestModel, NotificationService, UserCapabilityModel } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class CapabilitiesService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_getCapabilities = new HttpPaginatedRequestStream<UserCapabilityModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public capabilities$ = this.m_getCapabilities.response$;
    public isCapabilitiesLoading$ = this.m_getCapabilities.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public getCapabilities(options?: IPaginatedRequestModel): Observable<GetListUserCapabilitiesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/UserCapability/${getPaginatedRequestUrl(options)}`;        
        return this.m_getCapabilities.send({ url: url });
    }
}
