import { PaginatedResponseModel } from "../paginated-response.model";
import { UsageModel } from "./usage.model";

export class ProcessedFileFailure {
    id: number;
    createdDate: Date;
    filename: string;
    failureType: string;
    error: string;
    rawRecord: string;
}

export class ProcessedFileLog {
    id: number;
    createdDate: Date;
    fileLocation: string;
    message: string;
}

export class ProcessedFile {
    id: number;
    createdDate: Date;
    filename: string;
    parserName: string;
    failures: Array<ProcessedFileFailure> = [];
    usages: Array<UsageModel> = [];
    logs: Array<ProcessedFileLog> = [];
}


export class GetListProcessedFilesResponse extends PaginatedResponseModel<ProcessedFile> { }
export class GetProcessedFileResponse extends ProcessedFile { } 
export class CreateProcessedFileResponse extends ProcessedFile { } 
export class PatchProcessedFileResponse extends ProcessedFile { } 
export class DeleteProcessedFileResponse { } 

export class GetListProcessedFileFailuresResponse extends PaginatedResponseModel<ProcessedFileFailure> { }
export class GetListProcessedFileLogsResponse extends PaginatedResponseModel<ProcessedFileLog> { }
export class GetListProcessedFileUsagesResponse extends PaginatedResponseModel<UsageModel> { }