export class SimpleResponseModel {
    public status: string;
    public reason: string;
}

export class BulkResponseModel {
    public item_num: number;
    public errors: string;
}

export interface IPaginatedRequestModel {
    page?: number;
    pageSize?: number;
    // order?: string;
    sort?: string;
    search?: any;
}

export interface IXPaginationHeader {
    currentPage: number;
    pageSize: number;
    totalCount: number;
    previousPageLink: string;
    nextPageLink: string;
}

export class PaginatedModel {
    public count: number;
    public currentPage: number;
    public pageSize: number;
    public next: string;
    public previous: string;
}

export class PaginatedResponseModel<T_Response> extends PaginatedModel {
    public results: Array<T_Response>;
}
