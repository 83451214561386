import { PaginatedResponseModel } from "../paginated-response.model";

export class UserCapabilityModel {
    constructor(
        public id: number
        , public createdDate: Date
        , public modifiedDate: Date
        , public name: string
        , public description: string
        , public displayName: string
    ) { }
}

export class GetListUserCapabilitiesResponse extends PaginatedResponseModel<UserCapabilityModel> { }
export class CreateUserCapabilityResponse extends UserCapabilityModel { } 
export class PatchUserCapabilityResponse extends UserCapabilityModel { } 
export class DeleteUserCapabilityResponse { } 