import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LogModel, AppConfigurationService, IPaginatedRequestModel, NotificationService, GetListLogsResponse, GetLogResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class LogService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_getLogs = new HttpPaginatedRequestStream<LogModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public logs$ = this.m_getLogs.response$;
    public isLogsLoading$ = this.m_getLogs.isLoading$;

    private m_getLog = new HttpRequestStream<LogModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public log$ = this.m_getLog.response$;
    public isLogLoading$ = this.m_getLog.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public getLogs(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListLogsResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Log/${getPaginatedRequestUrl(options)}`;
        return this.m_getLogs.send({ url: url, isPoll: isPoll });
    }

    public getLog(id: number, isPoll = false): Observable<GetLogResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Log/${id}/`;
        return this.m_getLog.send({ url: url, isPoll: isPoll });
    }
}
