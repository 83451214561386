import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LogModel, AppConfigurationService, IPaginatedRequestModel, NotificationService, GetListLogsResponse, GetLogResponse, HttpMethod, JobInfo } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";
import { GetProcessedFileResponse, GetListProcessedFilesResponse, ProcessedFile } from "@app/ultisat/models/pharaoh/processed-file.model";


@Injectable()
export class ProcessedFilesService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_getProcessedFiles = new HttpPaginatedRequestStream<ProcessedFile>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public processedFiles$ = this.m_getProcessedFiles.response$;
    public isProcessedFilesLoading$ = this.m_getProcessedFiles.isLoading$;

    private m_getProcessedFile = new HttpRequestStream<ProcessedFile>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public processedFile$ = this.m_getProcessedFile.response$;
    public isProcessedFileLoading$ = this.m_getProcessedFile.isLoading$;

    private m_reprocessFailures = new HttpRequestStream<JobInfo>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public reprocessFailures$ = this.m_reprocessFailures.response$;
    public isReprocessFailuresLoading$ = this.m_reprocessFailures.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public getProcessedFiles(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListProcessedFilesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProcessedFile/${getPaginatedRequestUrl(options)}`;
        return this.m_getProcessedFiles.send({ url: url, isPoll: isPoll });
    }

    public getProcessedFile(id: number, isPoll = false): Observable<GetProcessedFileResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProcessedFile/${id}/`;
        return this.m_getProcessedFile.send({ url: url, isPoll: isPoll });
    }

    public postReprocessFileFailures(id: number): Observable<JobInfo> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProcessedFile/${id}/Reprocess`;
        return this.m_reprocessFailures.send({ url: url, isPoll: false, method: HttpMethod.POST });
    }
}
