import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { NumberModel, AppConfigurationService, CreateNumberResponse, DeleteNumberResponse, GetNumberResponse, GetListNumbersResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchNumberResponse, GetListNumbersByAccountResponse, BulkTerminateResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class NumberService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createNumber = new HttpRequestStream<NumberModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newNumber$ = this.m_createNumber.response$;
    public isCreateNumberLoading$ = this.m_createNumber.isLoading$;

    private m_getNumbers = new HttpPaginatedRequestStream<NumberModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public numbers$ = this.m_getNumbers.response$;
    public isNumbersLoading$ = this.m_getNumbers.isLoading$;

    private m_getNumbersByAccount = new HttpRequestStream<NumberModel[]>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public numbersByAccount$ = this.m_getNumbersByAccount.response$;
    public isNumbersByAccountLoading$ = this.m_getNumbers.isLoading$;

    private m_getNumber = new HttpRequestStream<NumberModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public number$ = this.m_getNumber.response$;
    public isNumberLoading$ = this.m_getNumber.isLoading$;

    private m_patchNumber = new HttpRequestStream<NumberModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchNumber$ = this.m_patchNumber.response$;
    public isPatchNumberLoading$ = this.m_patchNumber.isLoading$;

    private m_terminateNumber = new HttpRequestStream<NumberModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public terminateNumber$ = this.m_terminateNumber.response$;
    public isterminateNumberLoading$ = this.m_terminateNumber.isLoading$;

    private m_terminateNumbers = new HttpRequestStream<BulkTerminateResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public terminateNumbers$ = this.m_terminateNumbers.response$;
    public isterminateNumbersLoading$ = this.m_terminateNumbers.isLoading$;

    private m_updateNumber = new HttpRequestStream<NumberModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateNumber$ = this.m_patchNumber.response$;
    public isUpdateNumberLoading$ = this.m_patchNumber.isLoading$;

    private m_deleteNumber = new HttpRequestStream<DeleteNumberResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteNumber$ = this.m_deleteNumber.response$;
    public isDeleteNumberLoading$ = this.m_deleteNumber.isLoading$;

    private m_deleteNumbers = new HttpRequestStream<DeleteNumberResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteNumbers$ = this.m_deleteNumbers.response$;
    public isDeleteNumbersLoading$ = this.m_deleteNumbers.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createNumber(number: NumberModel): Observable<CreateNumberResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/`;
        return this.m_createNumber.send({ url: url, method: HttpMethod.POST, body: number });
    }

    public getNumbers(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListNumbersResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/${getPaginatedRequestUrl(options)}`;
        return this.m_getNumbers.send({ url: url, isPoll: isPoll });
    }

    public getNumbersByAccount(id: number, isPoll = false): Observable<GetListNumbersByAccountResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${id}/Number/`;
        return this.m_getNumbersByAccount.send({ url: url, isPoll: isPoll });
    }

    public getNumber(id: number, isPoll = false): Observable<GetNumberResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/${id}/`;
        return this.m_getNumber.send({ url: url, isPoll: isPoll });
    }

    public updateNumber(id: number, obj: any): Observable<PatchNumberResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/${id}/`;
        return this.m_updateNumber.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public patchNumber(id: number, obj: any): Observable<PatchNumberResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/${id}/`;
        return this.m_patchNumber.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public terminateNumber(id: number, obj: any): Observable<PatchNumberResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/terminate/${id}`;
        return this.m_terminateNumber.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public terminateNumbers(numbers: string[], endDate: Date): Observable<BulkTerminateResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/terminateBulk/`;
        const body = {
            Numbers: numbers,
            EndDate: endDate
        }
        return this.m_terminateNumbers.send({ url: url, method: HttpMethod.POST, body: body });
    }

    public deleteNumber(id: number): Observable<DeleteNumberResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/${id}/`;
        return this.m_deleteNumber.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteNumbers(ids: Array<number>): Observable<DeleteNumberResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Number/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteNumbers.send({ url: url, method: HttpMethod.DELETE });
    }
}
