import { PaginatedResponseModel } from "../paginated-response.model";
import { CountryModel } from "./country.model";

export class LocationModel {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    locationLookup: string;
    type: LocationType;
    countryId: number;
    country: CountryModel;
}

export enum LocationType {
    UNKNOWN
    , COUNTRY_LOOKUP
    , CARRIER_LOCATION
    , DIALED_DIGIT
    , PARSER
}

export class GetListLocationsResponse extends PaginatedResponseModel<LocationModel> { }
export class GetLocationResponse extends LocationModel { }
export class CreateLocationResponse extends LocationModel { }
export class PatchLocationResponse extends LocationModel { }
export class DeleteLocationResponse { } 