import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { environment } from '@env/environment';

declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styles: [`.in-development {
    display: inline-block;
    font-size: xxx-large;
    font-weight: bold;
    font-style: italic;
    padding-left: 15px;
  }
  .in-development > div { line-height: 49px; }`]
})
export class HeaderComponent implements OnInit {
  public isProduction = false;
  constructor(private router: Router) {
  }

  ngOnInit() {
    this.isProduction = (environment.production === true);
  }


  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }
}
