import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromAuth from '@app/core/store/auth';
import { AuthTokenService } from '@app/core/services';

@Injectable()
export class OAuthHeaderInterceptor implements HttpInterceptor {

    constructor(private m_auth: AuthTokenService, public store: Store<fromAuth.AuthState>) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers;

        // Always attach the access token
        if (this.m_auth.token) {
            headers = headers.set('Authorization', 'Bearer ' + this.m_auth.token);
        }

        // Attach the ID token if creating new user for AWS
        if (req.url.includes('/User/') && this.m_auth.idToken) {
            headers = headers.set('ID-Token', 'Bearer ' + this.m_auth.idToken);
        }

        // Clone the request with updated headers
        const authReq = req.clone({ headers });
        return next.handle(authReq).pipe(
            tap((event: HttpEvent<any>) => { }, (err: any) => {
                if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)) {
                    console.log('Authentication error, dispatching logout');
                    // token no longer valid, logging out
                    this.store.dispatch(new fromAuth.LogoutAction());
                }
            }
            )
        );

        // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //     // const token: string = this.m_auth.token;
        //     let token;
        //     if (req.url.includes('/User/')) {
        //         // if (request.url.includes('/User/') && request.method === 'POST') {
        //         token = this.m_auth.idToken;
        //         console.log('User token', token)
        //     } else {
        //         token = this.m_auth.token;
        //         console.log('Access token', token)
        //     }
        //     if (token) {
        //         // Logged in. Add token.
        //         return next.handle(
        //             req.clone({
        //                 headers: req.headers
        //                     .append('Authorization', 'Bearer ' + token)
        //                 // .append('Access-Control-Expose-Headers', "Www-Authenticate")
        //             })
        //         ).pipe(
        //             tap((event: HttpEvent<any>) => { }, (err: any) => {
        //                 if (err instanceof HttpErrorResponse && (err.status == 401 || err.status == 403)) {
        //                     // token no longer valid, logging out
        //                     this.store.dispatch(new fromAuth.LogoutAction());
        //                 }
        //             })
        //         );
        //     }
        //     // Not logged in. Continue without modification.
        //     return next.handle(req);
        // }        
    }
}
