import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";
import { Product } from "./product.model";
import { TierModel } from "./tier.model";
import { VendorModel } from "./vendor.model";

export class StockIdentifier {
    id: number;
    key: string;
    value: string;
}

export class StockState {
    id: number;
    createdDate: Date;
    modifiedDate: Date;

    stockId: number;
    stock: StockModel;

    accountId: number;
    account: Account;

    productId: number;
    product: Product;

    tierId: number;
    tier: TierModel;

    parentId: number;
    parent: StockState;

    startDate: Date;
    endDate: Date;

    nextChargePeriod: string;

    virtualChildren: Array<StockState>;
}

export class StockModel {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    status: string;
    cost: number;

    productId: number;
    product: Product;

    vendorId: number;
    vendor: VendorModel;

    poNumber: string;
    receiveDate: Date;

    history: Array<StockState>;
    identifiers: Array<StockIdentifier>;
}

export class CreateStockBulkModel {
    productId: number;
    vendorId: number;
    poNumber: string;
    receiveDate: Date;
    cost: number;
    csv: string;
    csvHeader: string;
}

export class GetListStocksResponse extends PaginatedResponseModel<StockModel> { }
export class GetStockResponse extends StockModel { } 
export class CreateStockResponse extends StockModel { } 
export class CreateStockBulkResponse extends Array<StockModel> { } 
export class UpdateStockResponse extends StockModel { } 
export class PatchStockResponse extends StockModel { } 
export class DeleteStockResponse { } 