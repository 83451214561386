import { PaginatedResponseModel } from "../paginated-response.model";

export class TierModel {
    id: number;
    name: string;
    description: string;
}

export class GetListTiersResponse extends PaginatedResponseModel<TierModel> { }
export class GetTierResponse extends TierModel { } 
export class CreateTierResponse extends TierModel { } 
export class PatchTierResponse extends TierModel { } 
export class DeleteTierResponse { } 