import { IXPaginationHeader } from "../models";
import { HttpResponseHeader } from "./http.request";

export interface ISelect2Pagination {
    more: boolean;
}

export interface ISelect2Selected<T> {
    id: number;
    text: string;
    data: T;
}

export interface ISelect2Results<T> {
    pagination: ISelect2Pagination;
    results: Array<ISelect2Selected<T>>;
}

export function readSelect2TransportPaginationHeaders<T>(data, textStatus, jqXHR): ISelect2Results<T> {
    const paginationHeader: IXPaginationHeader = JSON.parse(jqXHR.getResponseHeader(HttpResponseHeader.PAGINATION));
    const more = paginationHeader.currentPage * paginationHeader.pageSize < paginationHeader.totalCount;
    // This is bunk... what if there is no *.id or *.name??! need to figure out something cleaner
    const results:Array<ISelect2Selected<T>> = data.map(item => ({ id: item.id, text: item.name, data: item }));   
    return {
        results: results,
        pagination: {
            more: more
        }
    }
}