import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { TierModel, AppConfigurationService, CreateTierResponse, DeleteTierResponse, GetTierResponse, GetListTiersResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchTierResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class TierService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createTier = new HttpRequestStream<TierModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newTier$ = this.m_createTier.response$;
    public isCreateTierLoading$ = this.m_createTier.isLoading$;

    private m_getTiers = new HttpPaginatedRequestStream<TierModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public tiers$ = this.m_getTiers.response$;
    public isTiersLoading$ = this.m_getTiers.isLoading$;

    private m_getTier = new HttpRequestStream<TierModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public tier$ = this.m_getTier.response$;
    public isTierLoading$ = this.m_getTier.isLoading$;

    private m_patchTier = new HttpRequestStream<TierModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchTier$ = this.m_patchTier.response$;
    public isPatchTierLoading$ = this.m_patchTier.isLoading$;

    private m_updateTier = new HttpRequestStream<TierModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateTier$ = this.m_updateTier.response$;
    public isUpdateTierLoading$ = this.m_updateTier.isLoading$;

    private m_deleteTier = new HttpRequestStream<DeleteTierResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteTier$ = this.m_deleteTier.response$;
    public isDeleteTierLoading$ = this.m_deleteTier.isLoading$;

    private m_deleteTiers = new HttpRequestStream<DeleteTierResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteTiers$ = this.m_deleteTiers.response$;
    public isDeleteTiersLoading$ = this.m_deleteTiers.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createTier(tier: TierModel): Observable<CreateTierResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Tier/`;
        return this.m_createTier.send({ url: url, method: HttpMethod.POST, body: tier });
    }

    public getTiers(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListTiersResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Tier/${getPaginatedRequestUrl(options)}`;
        return this.m_getTiers.send({ url: url, isPoll: isPoll });
    }

    public getTier(id: number, isPoll = false): Observable<GetTierResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Tier/${id}/`;
        return this.m_getTier.send({ url: url, isPoll: isPoll });
    }

    public patchTier(id: number, obj: any): Observable<PatchTierResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Tier/${id}/`;
        return this.m_patchTier.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public updateTier(id: number, obj: any): Observable<PatchTierResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Tier/${id}/`;
        return this.m_updateTier.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public deleteTier(id: number): Observable<DeleteTierResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Tier/${id}/`;
        return this.m_deleteTier.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteTiers(ids: Array<number>): Observable<DeleteTierResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Tier/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteTiers.send({ url: url, method: HttpMethod.DELETE });
    }
}
