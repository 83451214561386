import { Injectable } from '@angular/core';
import { IConfirmationOptions, INotificationsConfigurationOptions, INotificationService, IConfirmationResult, INotificationsFormatters, TranslationService } from '@app/ultisat/models';

declare var $: any;

@Injectable()
export class NotificationSmartboxService implements INotificationService {
    private m_shortWait = 6000;
    private m_longWait = 10000;
    private m_neverDismiss = undefined;
    private m_infoColor = '#3276B1';
    private m_infoIcon = 'fa fa-info-circle tada animated';
    private m_warningColor = '#C79121';
    private m_warningIcon = 'fa fa-bell swing animated';
    private m_errorColor = '#C46A69';
    private m_errorIcon = 'fa fa-warning shake animated';
    private m_successColor = '#739E73';
    private m_successIcon = 'fa fa-check fadeInLeft animated';

    constructor(private translateService: TranslationService) {
    }

    smallBox(data, cb?) {
        $.smallBox(data, cb);
    }

    bigBox(data, cb?) {
        $.bigBox(data, cb);
    }

    smartMessageBox(data, cb?) {
        $.SmartMessageBox(data, cb);
    }

    private formatTitle(title: string, options: INotificationsFormatters): string {
        if (options) {
            const myTitle = options.translateTitle === true ? this.translateService.getTranslation(title) : title;
            if (options.titleFormatter) {
                return options.titleFormatter(myTitle);
            }
            return myTitle;
        }        
        return this.translateService.getTranslation(title);
    }

    private formatMessage(message: string, options: INotificationsFormatters): string {
        if (options) {
            const myMsg = options.translateMessage === true ? this.translateService.getTranslation(message) : message;
            if (options.messageFormatter) {
                return options.messageFormatter(myMsg);
            }
            return myMsg;
        }
        return this.translateService.getTranslation(message);
    }

    notifyInfo(title: string, message: string, options?: INotificationsConfigurationOptions): void {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        let icon = this.m_infoIcon;
        let smallIcon = null;
        if (options) {
            if (options.iconClass) {
                icon = options.iconClass;
            } else if (options.smallIconClass) {
                smallIcon = options.smallIconClass;
                icon = null;
            }
        }
        this.smallBox({
            title: myTitle,
            content: myMessage,
            color: options && options.bgColor ? options.bgColor : this.m_infoColor,
            icon: icon,
            iconSmall: smallIcon,
            timeout: options && options.timeout ? options.timeout : this.m_shortWait
        });
    }
    notifyWarning(title: string, message: string, options?: INotificationsConfigurationOptions): void {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        let icon = this.m_warningIcon;
        let smallIcon = null;
        if (options) {
            if (options.iconClass) {
                icon = options.iconClass;
            } else if (options.smallIconClass) {
                smallIcon = options.smallIconClass;
                icon = null;
            }
        }
        this.smallBox({
            title: myTitle,
            content: myMessage,
            color: options && options.bgColor ? options.bgColor : this.m_warningColor,
            icon: icon,
            iconSmall: smallIcon,
            timeout: options && options.timeout ? options.timeout : this.m_shortWait
        });
    }
    notifyError(title: string, message: string, options?: INotificationsConfigurationOptions): void {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        let icon = this.m_errorIcon;
        let smallIcon = null;
        if (options) {
            if (options.iconClass) {
                icon = options.iconClass;
            } else if (options.smallIconClass) {
                smallIcon = options.smallIconClass;
                icon = null;
            }
        }
        this.smallBox({
            title: myTitle,
            content: myMessage,
            color: options && options.bgColor ? options.bgColor : this.m_errorColor,
            icon: icon,
            iconSmall: smallIcon,
            timeout: options && options.timeout ? options.timeout : this.m_shortWait
        });
    }
    notifySuccess(title: string, message: string, options?: INotificationsConfigurationOptions): void {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        let icon = this.m_successIcon;
        let smallIcon = null;
        if (options) {
            if (options.iconClass) {
                icon = options.iconClass;
            } else if (options.smallIconClass) {
                smallIcon = options.smallIconClass;
                icon = null;
            }
        }
        this.smallBox({
            title: myTitle,
            content: myMessage,
            color: options && options.bgColor ? options.bgColor : this.m_successColor,
            icon: icon,
            iconSmall: smallIcon,
            timeout: options && options.timeout ? options.timeout : this.m_shortWait
        });
    }
    public messageInfo(title: string, message: string, options?: INotificationsConfigurationOptions): Promise<void> {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        return new Promise((resolve) => {
            this.bigBox({
                title: myTitle,
                content: myMessage,
                color: options && options.bgColor ? options.bgColor : this.m_infoColor,
                icon: options && options.iconClass ? options.iconClass : this.m_infoIcon,
                timeout: options && options.timeout ? options.timeout : this.m_longWait
            }, () => resolve());
        });
    }

    public messageWarning(title: string, message: string, options?: INotificationsConfigurationOptions): Promise<void> {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        return new Promise((resolve) => {
            this.bigBox({
                title: myTitle,
                content: myMessage,
                color: options && options.bgColor ? options.bgColor : this.m_warningColor,
                icon: options && options.iconClass ? options.iconClass : this.m_warningIcon,
                timeout: options && options.timeout ? options.timeout : this.m_longWait
            }, () => resolve());
        });
    }

    public messageError(title: string, message: string, options?: INotificationsConfigurationOptions): Promise<void> {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        return new Promise((resolve) => {
            this.bigBox({
                title: myTitle,
                content: myMessage,
                color: options && options.bgColor ? options.bgColor : this.m_errorColor,
                icon: options && options.iconClass ? options.iconClass : this.m_errorIcon,
                timeout: options && options.timeout ? options.timeout : this.m_neverDismiss
            }, () => resolve());
        });
    }

    public messageSuccess(title: string, message: string, options?: INotificationsConfigurationOptions): Promise<void> {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        return new Promise((resolve) => {
            this.bigBox({
                title: myTitle,
                content: myMessage,
                color: options && options.bgColor ? options.bgColor : this.m_successColor,
                icon: options && options.iconClass ? options.iconClass : this.m_successIcon,
                timeout: options && options.timeout ? options.timeout : this.m_longWait
            }, () => resolve());
        });
    }

    public confirmation(title: string, message: string, options?: IConfirmationOptions): Promise<IConfirmationResult> {
        const myTitle = this.formatTitle(title, options);
        const myMessage = this.formatMessage(message, options);
        let myButtonNames = ['Cancel', 'Ok'];
        let myPlaceholderText = null;
        let myInputType = null;
        let myInputSelectOptionsStr = null;
        if (options) {
            myButtonNames = (options.buttonNames) ? options.buttonNames : myButtonNames;
            myPlaceholderText = (options.inputPlaceholderText) ? this.translateService.getTranslation(options.inputPlaceholderText) : null;
            myInputType = (options.inputType) ? options.inputType : null;
            if (options.inputSelectOptions) {
                const myInputSelectOptions = options.inputSelectOptions.map(opt => this.translateService.getTranslation(opt));
                myInputSelectOptionsStr = '';
                myInputSelectOptions.forEach(str => {
                    myInputSelectOptionsStr += `[${str}]`;
                });
            }
        }

        const myButtonNamesStr = myButtonNames.map(name => `[${this.translateService.getTranslation(name)}]`).join('');
        return new Promise((resolve, reject) => {
            this.smartMessageBox({
                title: myTitle,
                content: myMessage,
                buttons: myButtonNamesStr,
                input: myInputType,
                options: myInputSelectOptionsStr,
                placeholder: myPlaceholderText
            }, (ButtonPress, Value) => {
                resolve({ buttonPressed: ButtonPress, inputValue: Value });
            });
        });
    }
}
