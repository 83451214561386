import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";
import { UserModel } from "./user.model";

export class LogModel {
    id: number;
    createdDate: Date;
    userId: number;
    // user: UserModel;
    accountId: number;
    // account: AccountModel;
    type: string;
    message: string;
}

export class GetListLogsResponse extends PaginatedResponseModel<LogModel> { }
export class GetLogResponse extends LogModel { } 
