import { PaginatedResponseModel } from "../paginated-response.model";

export class ReferenceValueModel {
    id: number;
    key: string;
    value: string;
}

export class GetListReferenceValuesResponse extends PaginatedResponseModel<ReferenceValueModel> { }
export class GetReferenceValueResponse extends ReferenceValueModel { } 
export class CreateReferenceValueResponse extends ReferenceValueModel { } 
export class PatchReferenceValueResponse extends ReferenceValueModel { } 
export class DeleteReferenceValueResponse { } 