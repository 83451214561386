import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap";
import { IPager, PaginationService, ReleaseNotesService } from "@app/ultisat/services";
import { GetListReleasesResponse, GetReleaseNotesResponse, ReleaseModel, ReleaseNotesModel } from "@app/ultisat/models";

@Component({
    selector: "app-release-notes",
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.css'],
    providers: [PaginationService]
})
export class ReleaseNotesComponent implements OnInit, OnDestroy {
    private static readonly RELEASES_PAGE_SIZE = 5;
    private m_ngUnsubscribe = new Subject();
    public isReleasesLoading: boolean = true;
    public isReleaseNotesLoading: boolean = true;
    public isErrorReleases: boolean = false;
    public errorReleasesMsg: string = '';
    public isErrorReleaseNotes: boolean = false;
    public pager: IPager;
    private m_currReleasesPage: number = 1;
    private m_currReleasesPageResponse: GetListReleasesResponse;
    private m_selectedReleaseNotesResponse: GetReleaseNotesResponse;
    public selectedRelease: ReleaseModel;
    public releaseNotes: ReleaseNotesModel;
    public releases: Array<ReleaseModel>;

    constructor(private m_releaseNotesSvc: ReleaseNotesService, private m_pagerSvc: PaginationService, private m_bsModalRef: BsModalRef) { }

    ngOnInit(): void {
        this.pager = this.m_pagerSvc.getPager(0);
        this.m_releaseNotesSvc.releases$.pipe(takeUntil(this.m_ngUnsubscribe)).subscribe(response => {
            this.isReleasesLoading = false;
            this.isReleaseNotesLoading = false;
            this.m_currReleasesPageResponse = response;
            if (response !== null) {
                if (response && response.results && response.results.length > 0) {
                    this.isErrorReleases = false;
                    this.releases = this.m_currReleasesPageResponse.results;
                    this.setPager();
                    this.selectedRelease = this.releases[0];
                    // this.isReleaseNotesLoading = true;
                    this.releaseNotes = new ReleaseNotesModel(this.selectedRelease, this.selectedRelease.notes);
                } else {
                    this.errorReleasesMsg = "There are no releases defined!";
                }
            } else {
                this.isErrorReleases = true;
                this.releases = [];
                this.selectedRelease = undefined;
            }
        });

        this.m_releaseNotesSvc.getReleasesPage({ page: this.m_currReleasesPage, pageSize: ReleaseNotesComponent.RELEASES_PAGE_SIZE });
        this.isReleasesLoading = true;
        this.isReleaseNotesLoading = true;
    }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    private setPager(): void {
        this.pager = this.m_pagerSvc.getPager(this.m_currReleasesPageResponse.count, this.m_currReleasesPage, ReleaseNotesComponent.RELEASES_PAGE_SIZE);
    }

    public setPage(page: number): void {
        if (this.m_currReleasesPage !== page && !(page > this.pager.totalPages) && !(page < 1)) {
            this.m_currReleasesPage = page;
            this.setPager();
            this.m_releaseNotesSvc.getReleasesPage({ page: page, pageSize: ReleaseNotesComponent.RELEASES_PAGE_SIZE });
        }
    }

    public closeModal(): void {
        this.m_bsModalRef.hide();
    }

    public selectRelease(release: ReleaseModel) {
        if (!release || release.id !== this.selectedRelease.id) {
            this.isReleaseNotesLoading = true;
            this.selectedRelease = release;
            this.releaseNotes = new ReleaseNotesModel(this.selectedRelease, this.selectedRelease.notes);
        }
    }

    public retry() {
        this.isReleasesLoading = true;
        this.isReleaseNotesLoading = true;
        this.m_releaseNotesSvc.getReleasesPage({ page: this.m_currReleasesPage, pageSize: ReleaseNotesComponent.RELEASES_PAGE_SIZE });
    }
}
