import * as _ from 'lodash';

import { InputType, CreditCardType, IRegexStringWithMessage } from '@app/ultisat/models';
import { NumberPrefixNames } from './utilityConstants';

const typeCache: { [label: string]: boolean } = {};

export type Predicate = (oldValues: Array<any>, newValues: Array<any>) => boolean;

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 * 
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels are unique.
 *
 * @param label
 */
export function type<T>(label: T | ''): T {
    if (typeCache[<string>label]) {
        throw new Error(`Action type "${label}" is not unqiue"`);
    }

    typeCache[<string>label] = true;

    return <T>label;
}

/**
 * Runs through every condition, compares new and old values and returns true/false depends on condition state.
 * This is used to distinct if two observable values have changed.
 *
 * @param oldValues
 * @param newValues
 * @param conditions
 */
export function distinctChanges(oldValues: Array<any>, newValues: Array<any>, conditions: Predicate[]): boolean {
    if (conditions.every(cond => cond(oldValues, newValues))) {
        return false
    };
    return true;
}

/**
 * Returns true if the given value is type of Object
 *
 * @param val
 */
export function isObject(val: any) {
    if (val === null) return false;

    return ((typeof val === 'function') || (typeof val === 'object'));
}

/**
 * Returns true if the given Object is empty.
 *  ie. 
 *  const obj = {}
 *  isObjectEmpty(obj); // === true
 *  
 * @param val
 */
export function isObjectEmpty(obj: any) {
    return (Object.keys(obj).length === 0 && obj.constructor === Object)
}

/**
 * Returns true if the given value is an Object and not Empty.
 *  ie. 
 *  const obj = {}
 *  isObjectEmpty(obj); // === true
 *  
 * @param val
 */
export function isObjectAndNotEmpty(obj: any) {
    if (isObject(obj)) {
        return !isObjectEmpty(obj);
    }
    return false;
}


/**
 * Capitalizes the first character in given string
 *
 * @param s
 */
export function capitalize(s: string) {
    if (!s || typeof s !== 'string') return s;
    return s && s[0].toUpperCase() + s.slice(1);
}

/**
 * Uncapitalizes the first character in given string
 *
 * @param s
 */
export function uncapitalize(s: string) {
    if (!s || typeof s !== 'string') return s;
    return s && s[0].toLowerCase() + s.slice(1);
}

/**
 * Checks if string is null or whitespace
 *
 * @param s
 */
export function isNullOrWhiteSpace(s: string | null) {
    return (!s || s.length === 0 || /^\s*$/.test(s));
}

/**
 * Flattens multi dimensional object into one level deep
 *
 * @param obj
 * @param preservePath
 */
export function flattenObject(ob: any, preservePath: boolean = false): any {
    var toReturn: any = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object') {
            var flatObject = flattenObject(ob[i], preservePath);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                let path = preservePath ? (i + '.' + x) : x;

                toReturn[path] = flatObject[x];
            }
        } else toReturn[i] = ob[i];
    }

    return toReturn;
}

/**
 * Returns formated date based on given culture
 *
 * @param dateString
 * @param culture
 */
export function localeDateString(dateString: string, culture: string = 'en-EN'): string {
    const date = new Date(dateString);
    return date.toLocaleString(culture);
}

/**
 * Clamps a number between a min and max value.
 * @param clamp The number that will be clamped
 * @param min The minimum value that clamp can be
 * @param max The maximum value that clamp can be
 */
export function mathClamp(clamp: number, min: number, max: number): number {
    return Math.min(Math.max(clamp, min), max);
}

/**
 * Gives a random number between a range of values
 * @param number min
 * @param number max
 * @returns
 */
export function randRange(min: number = Number.MIN_SAFE_INTEGER, max: number = Number.MAX_SAFE_INTEGER): number {
    const top = max - min;
    return Math.floor(Math.random() * top) + min;
}

/**
 * Play with version 3: http://www.pimptrizkit.com/?t=20%20Shades
 * 
 * The core math of this version is the same as before. But, I did some major refactoring. This has allowed for much greater functionality and control. It now inherently converts RGB2Hex and Hex2RGB.
 * 
 * All the old features from v2 above should still be here. I have tried to test it all, please post a comment if you find anything wrong. Anyhow, here are the new features:
 * 
 * Accepts 3 digit (or 4 digit) HEX color codes, in the form #RGB (or #ARGB). It will expand them. Delete the line marked with //3 digit to remove this feature.
 * Accepts and blends alpha channels. If either the from color or the to color has an alpha channel, then the result will have an alpha channel. If both colors have an alpha channel, the result will be a blend of the two alpha channels using the percentage given (just as if it were a normal color channel). If only one of the two colors has an alpha channel, this alpha will just be passed thru to the result. This allows one to blend/shade a transparent color while maintaining the transparent level. Or, if the transparent level should blend as well, make sure both colors have alphas. Shading will pass thru the alpha channel, if you want basic shading that also blends the alpha channel, then use rgb(0,0,0,1) or rgb(255,255,255,1) as your to color (or their hex equivalents). For RGB colors, the resulting alpha channel will be rounded to 4 decimal places.
 * RGB2Hex and Hex2RGB conversions are now implicit when using blending. The result color will always be in the form of the to color, if one exists. If there is no to color, then pass 'c' in as the to color and it will shade and convert. If conversion only is desired, then pass 0 as the percentage as well.
 * A secondary function is added to the global as well. sbcRip can be passed a hex or rbg color and it returns an object containing this color information. Its in the form: {0:R,1:G,2:B,0.3:A}. Where R G and B have range 0 to 255. And when there is no alpha: A is -1. Otherwise: A has range 0.0000 to 1.0000.
 * Minor Error Checking has been added. It's not perfect. It can still crash. But it will catch some stuff. Basically, if the structure is wrong in some ways or if the percentage is not a number or out of scope, it will return null. An example: shadeBlendConvert(0.5,"salt") = null , where as it thinks #salt is a valid color. Delete the four lines marked with //ErrorCheck to remove this feature.
 * @param p the percentage lighter (positive) or darker (negative) to make the color
 * @param from
 * @param to
 * @returns Newly shaded color
 */
export function shadeBlendConvert(p: number, from: string, to: string | undefined = undefined): string | null {
    if (typeof (p) != "number"
        || p < -1 || p > 1
        || typeof (from) != "string"
        || (from[0] != 'r' && from[0] != '#')
        || (typeof (to) != "string" && typeof (to) != "undefined")) {
        return null; //ErrorCheck
    }
    let sbcRip = (d: any) => {
        let l = d.length, RGB: any = new Object();
        if (l > 9) {
            d = d.split(",");
            if (d.length < 3 || d.length > 4) return null;//ErrorCheck
            RGB[0] = i(d[0].slice(4)), RGB[1] = i(d[1]), RGB[2] = i(d[2]), RGB[3] = d[3] ? parseFloat(d[3]) : -1;
        } else {
            if (l == 8 || l == 6 || l < 4) return null; //ErrorCheck
            if (l < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (l > 4 ? d[4] + "" + d[4] : ""); //3 digit
            d = i(d.slice(1), 16), RGB[0] = d >> 16 & 255, RGB[1] = d >> 8 & 255, RGB[2] = d & 255, RGB[3] = l == 9 || l == 5 ? r(((d >> 24 & 255) / 255) * 10000) / 10000 : -1;
        }
        return RGB;
    }
    var i = parseInt, r = Math.round, h = from.length > 9, h = typeof (to) == "string" ? to.length > 9 ? true : to == "c" ? !h : false : h, b = p < 0, p = b ? p * -1 : p, to: string | undefined = to && to != "c" ? to : b ? "#000000" : "#FFFFFF", f = sbcRip(from), t = sbcRip(to);
    if (!f || !t) {
        return null; //ErrorCheck
    }
    if (h) {
        return "rgb(" + r((t[0] - f[0]) * p + f[0]) + "," + r((t[1] - f[1]) * p + f[1]) + "," + r((t[2] - f[2]) * p + f[2]) + (f[3] < 0 && t[3] < 0 ? ")" : "," + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p + f[3]) * 10000) / 10000 : t[3] < 0 ? f[3] : t[3]) + ")");
    } else {
        return "#" + (0x100000000 + (f[3] > -1 && t[3] > -1 ? r(((t[3] - f[3]) * p + f[3]) * 255) : t[3] > -1 ? r(t[3] * 255) : f[3] > -1 ? r(f[3] * 255) : 255) * 0x1000000 + r((t[0] - f[0]) * p + f[0]) * 0x10000 + r((t[1] - f[1]) * p + f[1]) * 0x100 + r((t[2] - f[2]) * p + f[2])).toString(16).slice(f[3] > -1 || t[3] > -1 ? 1 : 3);
    }
}

/**
 * Get a contrasting color
 *  * If the color passed in is above a certain brightness,
 *  *  the contrast will be 'black', else 'white'.
 * @param hexcolor
 * @returns
 */
export function getColorContrastYIQ(hexcolor: string): string {
    if (!hexcolor || !hexcolor.length) {
        return null;
    } else if (hexcolor[0] == '#') {
        hexcolor = hexcolor.slice(1);
    }
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
}

/**
 * Get the HEX value of a color by name
 * @param colour The name of the color
 * @returns The HEX value of the given color
 */
export function colourNameToHex(colour: string): string | false {
    const colours = {
        "aliceblue": "#f0f8ff", "antiquewhite": "#faebd7", "aqua": "#00ffff", "aquamarine": "#7fffd4", "azure": "#f0ffff",
        "beige": "#f5f5dc", "bisque": "#ffe4c4", "black": "#000000", "blanchedalmond": "#ffebcd", "blue": "#0000ff", "blueviolet": "#8a2be2", "brown": "#a52a2a", "burlywood": "#deb887",
        "cadetblue": "#5f9ea0", "chartreuse": "#7fff00", "chocolate": "#d2691e", "coral": "#ff7f50", "cornflowerblue": "#6495ed", "cornsilk": "#fff8dc", "crimson": "#dc143c", "cyan": "#00ffff",
        "darkblue": "#00008b", "darkcyan": "#008b8b", "darkgoldenrod": "#b8860b", "darkgray": "#a9a9a9", "darkgreen": "#006400", "darkkhaki": "#bdb76b", "darkmagenta": "#8b008b", "darkolivegreen": "#556b2f",
        "darkorange": "#ff8c00", "darkorchid": "#9932cc", "darkred": "#8b0000", "darksalmon": "#e9967a", "darkseagreen": "#8fbc8f", "darkslateblue": "#483d8b", "darkslategray": "#2f4f4f", "darkturquoise": "#00ced1",
        "darkviolet": "#9400d3", "deeppink": "#ff1493", "deepskyblue": "#00bfff", "dimgray": "#696969", "dodgerblue": "#1e90ff",
        "firebrick": "#b22222", "floralwhite": "#fffaf0", "forestgreen": "#228b22", "fuchsia": "#ff00ff",
        "gainsboro": "#dcdcdc", "ghostwhite": "#f8f8ff", "gold": "#ffd700", "goldenrod": "#daa520", "gray": "#808080", "green": "#008000", "greenyellow": "#adff2f",
        "honeydew": "#f0fff0", "hotpink": "#ff69b4",
        "indianred ": "#cd5c5c", "indigo": "#4b0082", "ivory": "#fffff0", "khaki": "#f0e68c",
        "lavender": "#e6e6fa", "lavenderblush": "#fff0f5", "lawngreen": "#7cfc00", "lemonchiffon": "#fffacd", "lightblue": "#add8e6", "lightcoral": "#f08080", "lightcyan": "#e0ffff", "lightgoldenrodyellow": "#fafad2",
        "lightgrey": "#d3d3d3", "lightgreen": "#90ee90", "lightpink": "#ffb6c1", "lightsalmon": "#ffa07a", "lightseagreen": "#20b2aa", "lightskyblue": "#87cefa", "lightslategray": "#778899", "lightsteelblue": "#b0c4de",
        "lightyellow": "#ffffe0", "lime": "#00ff00", "limegreen": "#32cd32", "linen": "#faf0e6",
        "magenta": "#ff00ff", "maroon": "#800000", "mediumaquamarine": "#66cdaa", "mediumblue": "#0000cd", "mediumorchid": "#ba55d3", "mediumpurple": "#9370d8", "mediumseagreen": "#3cb371", "mediumslateblue": "#7b68ee",
        "mediumspringgreen": "#00fa9a", "mediumturquoise": "#48d1cc", "mediumvioletred": "#c71585", "midnightblue": "#191970", "mintcream": "#f5fffa", "mistyrose": "#ffe4e1", "moccasin": "#ffe4b5",
        "navajowhite": "#ffdead", "navy": "#000080",
        "oldlace": "#fdf5e6", "olive": "#808000", "olivedrab": "#6b8e23", "orange": "#ffa500", "orangered": "#ff4500", "orchid": "#da70d6",
        "palegoldenrod": "#eee8aa", "palegreen": "#98fb98", "paleturquoise": "#afeeee", "palevioletred": "#d87093", "papayawhip": "#ffefd5", "peachpuff": "#ffdab9", "peru": "#cd853f", "pink": "#ffc0cb", "plum": "#dda0dd", "powderblue": "#b0e0e6", "purple": "#800080",
        "rebeccapurple": "#663399", "red": "#ff0000", "rosybrown": "#bc8f8f", "royalblue": "#4169e1",
        "saddlebrown": "#8b4513", "salmon": "#fa8072", "sandybrown": "#f4a460", "seagreen": "#2e8b57", "seashell": "#fff5ee", "sienna": "#a0522d", "silver": "#c0c0c0", "skyblue": "#87ceeb", "slateblue": "#6a5acd", "slategray": "#708090", "snow": "#fffafa", "springgreen": "#00ff7f", "steelblue": "#4682b4",
        "tan": "#d2b48c", "teal": "#008080", "thistle": "#d8bfd8", "tomato": "#ff6347", "turquoise": "#40e0d0",
        "violet": "#ee82ee",
        "wheat": "#f5deb3", "white": "#ffffff", "whitesmoke": "#f5f5f5",
        "yellow": "#ffff00", "yellowgreen": "#9acd32"
    };
    const finder = colour.toLowerCase();
    if (typeof colours.hasOwnProperty(finder)) {
        return colours[finder];
    }

    return false;
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

/**
 * Returns a string that represents a color in 6 digit HEX format
 *  ie. #FFFFFF
 * @param color A name of a color or an rgb(), rgba() value
 * @returns 6 digit HEX format or null
 */
export function getHexValueForColor(color: string): string {
    if (isNullOrWhiteSpace(color)) { return null; }
    let hexValue = color;
    if (color[0] !== '#') {
        if (color[0] === 'r' && color[1] === 'g' && color[2] === 'b' && (color[3] === '(' || (color[3] === 'a' && color[4] === '('))) {
            const components = color.split(',');
            const r = components[0].split('(')[1];
            const g = components[1];
            const b = components[2];
            hexValue = rgbToHex(r, g, b);
        } else {
            const fromName = colourNameToHex(color);
            if (fromName === false) {
                return null;
            }
            hexValue = fromName;
        }
    }
    return hexValue;
}

/**
 * Gets the Regular Expresssion for the given known InputType
 * @param type
 * @returns
 */
export function getInputTypeRegex(type: InputType): IRegexStringWithMessage {
    switch (type) {
        case InputType.IP_ADDRESS:
            return {
                invalidMessage: "Not a valid IP Address"
                , regExString: "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
            };
        case InputType.ALPHANUMERIC_ONLY:
            return {
                invalidMessage: "Must be a valid alphanumeric"
                , regExString: "^[a-zA-Z0-9_-]*$"
            }
        case InputType.ALPHANUMERIC_SPACES_PERIODS:
            return {
                invalidMessage: "Must be a valid alphanumeric, space, or period"
                , regExString: "^[a-zA-Z0-9_-\\s\\.]*$"
            }
        case InputType.NUMERIC:
        //intentional fall through
        case InputType.UNKNOWN:
        //intentional fall through
        default:
            return {
                invalidMessage: "Not a number"
                , regExString: '^-?\\d*\.?\\d+$'
            }
    }
}

/**
 * Given a credit card number, gives the type of card.
 * @param creditCardNumber
 * @returns The type of credit card of the number given, else unknown.
 */
export function getCreditCardTypeFromNumber(creditCardNumber: string): CreditCardType {
    _.forEach(CreditCardType, cc => {
        let regExStr: string = '';
        switch (cc) {
            case CreditCardType.VISA:
                regExStr = "^4[0-9]{12}(?:[0-9]{3})?$";
                break;
            case CreditCardType.MASTERCARD:
                regExStr = "^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$";
                break;
            case CreditCardType.AMERICAN_EXPRESS:
                regExStr = "^3[47][0-9]{13}$";
                break;
            case CreditCardType.DISCOVER:
                regExStr = "^6(?:011|5[0-9]{2})[0-9]{12}$";
                break;
            case CreditCardType.DINERS_CLUB:
                regExStr = "^3(?:0[0-5]|[68][0-9])[0-9]{11}$";
                break;
            case CreditCardType.JCB:
                regExStr = "^(?:2131|1800|35\d{3})\d{11}$";
                break;
        }
        if (regExStr !== '') {
            let regex = RegExp(regExStr);
            if (regex.test(regExStr)) {
                return cc;
            }
        }
    });
    return CreditCardType.UNKNOWN;
}

/**
 * Round a number to a precise decimal place.
 *  * Should only be used to account for floating point imprecision
 * @param number The full number to round off
 * @param precision The number of decimal places to round to
 * @returns
 */
export function preciseRound(number: number, precision: number): number {
    var shift = function (number: number, exponent: number) {
        var numArray = ("" + number).split("e");
        return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + exponent) : exponent));
    };
    return shift(Math.round(shift(number, +precision)), -precision);
}

/**
 * Gives the prefixed abbreviation for the 
 *  number supplied
 * @param value The value to be abbreviated
 * @param precision The number of decimal places to show
 * @returns
 */
export function formatNumberAbbrev(value: number, precision: number = 1): string {
    if (value === 0) {
        return '0';
    }
    let sign = '';
    if (value < 0) {
        sign = '-';
    }
    const absVal = Math.abs(value);
    if (absVal > NumberPrefixNames.KILO) {
        if (absVal > NumberPrefixNames.YOTTA) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.YOTTA), precision);
            return `${sign}${abbrv}Y`;
        } else if (absVal > NumberPrefixNames.ZETTA) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.ZETTA), precision);
            return `${sign}${abbrv}Z`;
        } else if (absVal > NumberPrefixNames.EXA) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.EXA), precision);
            return `${sign}${abbrv}E`;
        } else if (absVal > NumberPrefixNames.PETA) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.PETA), precision);
            return `${sign}${abbrv}P`;
        } else if (absVal > NumberPrefixNames.TERA) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.TERA), precision);
            return `${sign}${abbrv}T`;
        } else if (absVal > NumberPrefixNames.GIGA) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.GIGA), precision);
            return `${sign}${abbrv}G`;
        } else if (absVal > NumberPrefixNames.MEGA) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.MEGA), precision);
            return `${sign}${abbrv}M`;
        } else {
            const abbrv = preciseRound((absVal / NumberPrefixNames.KILO), precision);
            return `${sign}${abbrv}k`;
        }
    } else if (absVal < NumberPrefixNames.MILLI) {
        if (absVal < NumberPrefixNames.YOCTO) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.YOCTO), precision);
            return `${sign}${abbrv}y`;
        } else if (absVal < NumberPrefixNames.ZEPTO) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.ZEPTO), precision);
            return `${sign}${abbrv}z`;
        } else if (absVal < NumberPrefixNames.ATTO) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.ATTO), precision);
            return `${sign}${abbrv}a`;
        } else if (absVal < NumberPrefixNames.FEMTO) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.FEMTO), precision);
            return `${sign}${abbrv}f`;
        } else if (absVal < NumberPrefixNames.PICO) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.PICO), precision);
            return `${sign}${abbrv}p`;
        } else if (absVal < NumberPrefixNames.NANO) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.NANO), precision);
            return `${sign}${abbrv}n`;
        } else if (absVal < NumberPrefixNames.MICRO) {
            const abbrv = preciseRound((absVal / NumberPrefixNames.MICRO), precision);
            return `${sign}${abbrv}�`;
        } else {
            const abbrv = preciseRound((absVal / NumberPrefixNames.MILLI), precision);
            return `${sign}${abbrv}m`;
        }
    }
    const rnd = preciseRound(absVal, precision);
    return `${sign}${rnd}`;
}

/**
 * Converts an object to a query string ready for a URI
 * @param obj 
 * @returns 
 */
export function objectToQueryString(obj: any) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
        const value = obj[key];
        if (Array.isArray(value)) {
            let ret = '';
            for (let i = 0; i < value.length; ++i) {
                if (i > 0) {
                    ret += '&';
                }
                const item = value[i];
                ret += encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(item))
            }
            return ret;
        } else {
            return encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(value));
        }
    });
    return keyValuePairs.join('&');
}

export interface ITreeNode {
    children: Array<ITreeNode>;
}

export function extractTreeChildren(treeNode: ITreeNode): Array<ITreeNode> {
    return treeNode.children;
}

export function flattenTree(children: ITreeNode, childrenExtractor: (n: ITreeNode) => Array<ITreeNode>): Array<ITreeNode> {
    function flattenTreeOperator(children: Array<ITreeNode>, childrenExtractor: (n: ITreeNode) => Array<ITreeNode>): Array<ITreeNode> {
        return Array.prototype.concat.apply(children, children.map(x => flattenTreeOperator(childrenExtractor(x) || [], childrenExtractor)));
    }
    return flattenTreeOperator(childrenExtractor(children), childrenExtractor).map(x => delete x.children && x);
}