import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";
import { Product, ProductPricing } from "./product.model";
import { TierModel } from "./tier.model";

export class OrderFulfillmentLine {
    carrierId?: number;
    ratePlanId?: number;
    orderedProductId: number;
    internal?: Array<string> = [];
    external?: Array<string> = [];
    csv: string;    
}

export class OrderFulfillment {
    orderId: number;
    startDate: Date;
    lines: Array<OrderFulfillmentLine> = [];    
}

export class OrderedProduct {
    id: number;

    orderId: number;
    order: Order;

    parentId: number;
    parent: OrderedProduct;

    productId: number;
    product: Product;

    price: number;
    priceOverride?: number;
    frequency: string;
    quantity: number;
    total: number;
    discount: number;

    tierId: number;
    tier: TierModel;

    children: Array<OrderedProduct> = [];
    allowedChildren: Array<ProductPricing> = [];
    requiredChildren: Array<ProductPricing> = [];
}

export class Order {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    total: number;
    subTotal: number;
    discounts: number;
    status: string;

    accountId: number;
    account: Account;

    orderedProduct: Array<OrderedProduct> = [];
}

export class GetListOrdersResponse extends PaginatedResponseModel<Order> { }
export class GetOrderResponse extends Order { }
export class CreateOrderResponse extends Order { }
export class PatchOrderResponse extends Order { }
export class DeleteOrderResponse { } 