import { Pipe, PipeTransform } from '@angular/core';
import { UsageType } from '../models';

@Pipe({
    name: 'invoiceChargeUsageSubtype',
    pure: false
})
export class InvoiceChargeUsageSubtypePipe implements PipeTransform {

    constructor() { }

    transform(subType: number, args?: any): any {        
        // if (Object.values(UsageType).includes(subType as any)) {
            return UsageType[subType];
        // }
        // return subType;
    }
}
