import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Order, AppConfigurationService, CreateOrderResponse, DeleteOrderResponse, GetOrderResponse, GetListOrdersResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchOrderResponse, OrderFulfillment } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class OrderService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createOrder = new HttpRequestStream<Order>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newOrder$ = this.m_createOrder.response$;
    public isCreateOrderLoading$ = this.m_createOrder.isLoading$;

    private m_getOrders = new HttpPaginatedRequestStream<Order>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public orders$ = this.m_getOrders.response$;
    public isOrdersLoading$ = this.m_getOrders.isLoading$;

    private m_getOrder = new HttpRequestStream<Order>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public order$ = this.m_getOrder.response$;
    public isOrderLoading$ = this.m_getOrder.isLoading$;

    private m_patchOrder = new HttpRequestStream<Order>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchOrder$ = this.m_patchOrder.response$;
    public isPatchOrderLoading$ = this.m_patchOrder.isLoading$;

    private m_deleteOrder = new HttpRequestStream<DeleteOrderResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteOrder$ = this.m_deleteOrder.response$;
    public isDeleteOrderLoading$ = this.m_deleteOrder.isLoading$;

    private m_deleteOrders = new HttpRequestStream<DeleteOrderResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteOrders$ = this.m_deleteOrders.response$;
    public isDeleteOrdersLoading$ = this.m_deleteOrders.isLoading$;

    private m_fulfillOrder = new HttpRequestStream<Order>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public fulfillOrder$ = this.m_fulfillOrder.response$;
    public isFulfillOrderLoading$ = this.m_fulfillOrder.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createOrder(order: Order): Observable<CreateOrderResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Order/`;
        return this.m_createOrder.send({ url: url, method: HttpMethod.POST, body: order });
    }

    public getOrders(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListOrdersResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Order/${getPaginatedRequestUrl(options)}`;
        return this.m_getOrders.send({ url: url, isPoll: isPoll });
    }

    public getOrder(id: number, flatten: boolean = false, isPoll = false): Observable<GetOrderResponse> {
        let url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Order/${id}/`;
        if (flatten === true) {
            url += "?flattenOrderedProduct=true"
        }
        return this.m_getOrder.send({ url: url, isPoll: isPoll });
    }

    public patchOrder(id: number, obj: any): Observable<PatchOrderResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Order/${id}/`;
        return this.m_patchOrder.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteOrder(id: number): Observable<DeleteOrderResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Order/${id}/`;
        return this.m_deleteOrder.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteOrders(ids: Array<number>): Observable<DeleteOrderResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Order/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteOrders.send({ url: url, method: HttpMethod.DELETE });
    }
    
    public fulfillOrder(fulfillment: OrderFulfillment) {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Order/fulfill`;
        return this.m_fulfillOrder.send({ url: url, method: HttpMethod.POST, body: fulfillment });
    }
}
