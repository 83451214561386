﻿import { UserModel } from "../pharaoh/user.model";

export interface IAuthenticationService {
    login(options?: any): Promise<any>;
    completeLogin(options?: any): Promise<any>;
    logout(): Promise<any>;
    isAuthenticated(): Promise<boolean>;
    forgotPassword(options?: any): Promise<any>;
    changePassword(options?: any): Promise<any>;
}

export abstract class AuthenticationService implements IAuthenticationService {
    abstract login(options?: any): Promise<any>;
    abstract completeLogin(options?: any): Promise<any>;
    abstract logout(): Promise<any>;
    abstract isAuthenticated(): Promise<boolean>;
    abstract forgotPassword(options?: any): Promise<any>;
    abstract changePassword(options?: any): Promise<any>;
}