import { BulkTerminationModal } from './bulkTermination-modal/bulkTermination-modal.component';
import { ContactComponent } from './contact/contact.component'
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { PaymentComponent } from './payment/payment.component';
import { ReleaseNotesComponent } from './releaseNotes/release-notes.component';
import { JobInfoComponent } from './jobinfo/jobinfo.component';

export const components = [
    BulkTerminationModal,
    ContactComponent,
    ContactModalComponent,
    JobInfoComponent,
    PaymentComponent,
    ReleaseNotesComponent,
];

export const entryComponents = [
    ReleaseNotesComponent,
];

export * from './bulkTermination-modal/bulkTermination-modal.component';
export * from './contact/contact.component';
export * from './contact-modal/contact-modal.component';
export * from './jobinfo/jobinfo.component';
export * from './payment/payment.component';
export * from './releaseNotes/release-notes.component';