import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { StockModel, AppConfigurationService, CreateStockResponse, DeleteStockResponse, GetStockResponse, GetListStocksResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchStockResponse, UpdateStockResponse, CreateStockBulkResponse, CreateStockBulkModel } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class StockService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createStock = new HttpRequestStream<StockModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newStock$ = this.m_createStock.response$;
    public isCreateStockLoading$ = this.m_createStock.isLoading$;

    private m_createStockBulk = new HttpRequestStream<CreateStockBulkResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public stockBulk$ = this.m_createStockBulk.response$;
    public isCreateStockBulkLoading$ = this.m_createStockBulk.isLoading$;

    private m_getStockList = new HttpPaginatedRequestStream<StockModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public stockList$ = this.m_getStockList.response$;
    public isStockListLoading$ = this.m_getStockList.isLoading$;

    private m_getStock = new HttpRequestStream<StockModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public stock$ = this.m_getStock.response$;
    public isStockLoading$ = this.m_getStock.isLoading$;

    private m_updateStock = new HttpRequestStream<StockModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateStock$ = this.m_updateStock.response$;
    public isUpdateStockLoading$ = this.m_updateStock.isLoading$;

    private m_patchStock = new HttpRequestStream<StockModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchStock$ = this.m_patchStock.response$;
    public isPatchStockLoading$ = this.m_patchStock.isLoading$;

    private m_deleteStock = new HttpRequestStream<DeleteStockResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteStock$ = this.m_deleteStock.response$;
    public isDeleteStockLoading$ = this.m_deleteStock.isLoading$;

    private m_deleteStocks = new HttpRequestStream<DeleteStockResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteStocks$ = this.m_deleteStocks.response$;
    public isDeleteStocksLoading$ = this.m_deleteStocks.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createStock(stock: StockModel): Observable<CreateStockResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Stock/`;
        return this.m_createStock.send({ url: url, method: HttpMethod.POST, body: stock });
    }

    public createStockBulk(stock: CreateStockBulkModel): Observable<CreateStockBulkResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Stock/bulk`;
        return this.m_createStockBulk.send({ url: url, method: HttpMethod.POST, body: stock });
    }

    public getStockList(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListStocksResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Stock/${getPaginatedRequestUrl(options)}`;
        return this.m_getStockList.send({ url: url, isPoll: isPoll });
    }

    public getStock(id: number, isPoll = false): Observable<GetStockResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Stock/${id}/`;
        return this.m_getStock.send({ url: url, isPoll: isPoll });
    }

    public updateStock(id: number, obj: any): Observable<UpdateStockResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Stock/${id}/`;
        return this.m_patchStock.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public patchStock(id: number, obj: any): Observable<PatchStockResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Stock/${id}/`;
        return this.m_patchStock.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteStock(id: number): Observable<DeleteStockResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Stock/${id}/`;
        return this.m_deleteStock.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteStocks(ids: Array<number>): Observable<DeleteStockResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Stock/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteStocks.send({ url: url, method: HttpMethod.DELETE });
    }
}
