import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, OnDestroy } from "@angular/core";
import { addClassName, removeClassName } from "../../../utils/dom-helpers";
import "select2/dist/js/select2.min.js";

@Directive({
  selector: "[select2]"
})
export class Select2Directive implements OnInit, OnDestroy {

  @Input() options: any = null;
  @Output() change = new EventEmitter<any>();
  @Output() data = new EventEmitter<any>();
  @Output() select2Close = new EventEmitter<any>();

  constructor(private el: ElementRef) {
    addClassName(this.el.nativeElement, ["sa-cloak", "sa-hidden"]);
  }

  ngOnInit() {
    const el = $(this.el.nativeElement);
    if (this.options !== null && typeof (this.options) === 'object') {
      el.select2(this.options);
    } else {
      el.select2();
    }

    el.on('select2:close', ($eventData) => this.onSelect2Close($eventData));
    el.on('select2:select', ($eventData) => {
      this.change.emit($eventData);
      if ($eventData && $eventData.params && $eventData.params.data) {
        this.data.emit($eventData.params.data);
      }
    });

    removeClassName(this.el.nativeElement, ["sa-hidden"]);
  }

  ngOnDestroy(): void {
    const el = $(this.el.nativeElement);
    el.off();
    el.select2('destroy');
  }

  private onSelect2Close($eventData): void {
    this.select2Close.emit($eventData);
  }
}
