﻿import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currency-us',
    pure: false
})
export class CurrencyUSPipe implements PipeTransform {

    constructor() { }

    transform(currency: any, args?: any): any {
        return Math.ceil(currency * 100) / 100;
    }

}
