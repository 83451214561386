﻿import { UserCapabilityModel } from "../pharaoh/usercapability.model";

export interface IAuthorizationService {
    isInitialized(): boolean;
    initializePermissions(): Promise<any>;
    hasCapability(capability: string): boolean;
}

export abstract class AuthorizationService implements IAuthorizationService {
    abstract isInitialized(): boolean;
    abstract initializePermissions(): Promise<any>;
    abstract hasCapability(capability: string): boolean;
}