import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "@app/core/services/user.service";
import { NotificationService as shitshow } from "@app/core/services";
import { AuthenticationService, NotificationService } from "@app/ultisat/models";

@Component({
  selector: "sa-logout",
  template: `
<div id="logout" (click)="showPopup()" class="btn-header transparent pull-right">
        <span> <a title="Sign Out"><i class="fa fa-sign-out"></i></a> </span>
    </div>
  `,
  styles: []
})
export class LogoutComponent implements OnInit {

  public user

  constructor(
    private userService: UserService,
    private authenticationSvc: AuthenticationService,
    private router: Router,
    private shitshow: shitshow,
    private notificationService: NotificationService
  ) {
  }

  showPopup() {
    this.shitshow.smartMessageBox(
      {
        title:
          "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + this.userService.user$.value.username + "</strong></span> ?",
        content:
          "You can improve your security further after logging out by closing this opened browser",
        buttons: "[No][Yes]"
      },
      ButtonPressed => {
        if (ButtonPressed == "Yes") {
          this.logout();
        }
      }
    );
  }

  logout() {
    this.authenticationSvc.logout().then(result => {
      this.notificationService.notifySuccess("Logged Out", result.message);
      this.userService.logout();
      this.router.navigate(["/auth/login"]);
    }).catch(error => {
      console.error("Logout Error", error.error)
      this.notificationService.notifyError("Logout Error", error.message);
    });
  }

  ngOnInit() { }
}
