﻿export enum HttpMethod {
    GET
    , POST
    , PUT
    , PATCH
    , DELETE

    , TRACE
    , CONNECT
    , HEAD
    , OPTIONS
}

export enum InputType {
    ALPHANUMERIC_ONLY
    , ALPHANUMERIC_SPACES_PERIODS
    , NUMERIC
    , IP_ADDRESS

    , UNKNOWN
    , INVALID
}

export enum CreditCardType {
    VISA
    , MASTERCARD
    , AMERICAN_EXPRESS
    , DINERS_CLUB
    , DISCOVER
    , JCB

    , UNKNOWN
    , INVALID
}