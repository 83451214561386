﻿import { Injectable } from "@angular/core";
import { AuthenticationService, UserModel } from "@app/ultisat/models";

@Injectable()
export class AuthenticationTestService extends AuthenticationService {
    getAuthorizationHeaderValue(): string {
        return ``;
    }

    getUser(): Promise<UserModel> {
        return new Promise<UserModel>((resolve) => {
            const user: UserModel = {
                id: 1,
                username: "testUser",
                password: undefined,
                displayName: "Test User",
                firstname: "Test",
                lastname: "User",
                email: "test.user@ultisat.com",
            }
            // resolve(new UserModel("testUser", undefined, "Test User", "Test", "User", "test.user@ultisat.com"));
            resolve(user);
        });
    }

    login(): Promise<any> {       
        return new Promise(resolve => {
            resolve(true);
        });
    }
    completeLogin(): Promise<any> {        
        return new Promise(resolve => {
            resolve(true);
        });
    }

    logout(): Promise<any> {
        return new Promise(resolve => {
            resolve(true);
        });
    }
    isAuthenticated(): Promise<boolean> {
        return new Promise(resolve => {
            resolve(true);
        });
    }
    forgotPassword(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    changePassword(): Promise<any> {
        throw new Error("Method not implemented.");
    }
}