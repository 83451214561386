import { Injectable } from "@angular/core";
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthTokenService, UserService } from "@app/core/services";
import { AppConfigurationService, AuthenticationService, UserModel } from "@app/ultisat/models";
import { isNullOrWhiteSpace } from "@app/ultisat/utility";

@Injectable()
export class AuthenticationCognitoService extends AuthenticationService {
    private m_environment: any;
    private m_user: UserModel = null;
    private m_cognitoUser: CognitoUser;

    constructor(
        // private m_cognitoUtil: CognitoUtil
        private m_userSvc: UserService,
        private m_tokenSvc: AuthTokenService,
        appConfigSvc: AppConfigurationService
    ) {
        super();
        this.m_environment = appConfigSvc.authentication;
    }

    // // Method to get the current CognitoUser
    getCognitoUser(): CognitoUser {
        return this.m_cognitoUser;
    }

    private getUserFromCurrentUserPool(username: string): CognitoUser {
        const poolData = {
            UserPoolId: this.m_environment.COGNITO_USERPOOL_ID
            , ClientId: this.m_environment.COGNITO_CLIENT_ID
        };
        const userPool = new CognitoUserPool(poolData);

        let userData = {
            Username: username,
            Pool: userPool
        };

        let cognitoUser = new CognitoUser(userData);
        return cognitoUser;
    }

    private convertIdTokenPayloadToUserInfo(payload: any): UserModel {
        let user: UserModel = null;
        if (payload && payload.hasOwnProperty('cognito:username')) {
            user = new UserModel();
            user.username = payload['cognito:username']
            // user = new UserModel(payload['cognito:username']);
            if (payload.hasOwnProperty('email')) {
                user.email = payload.email;
            }
            if (payload.hasOwnProperty('email_verified')) {
                user.isEmailVerified = payload.email_verified;
            }
            if (payload.hasOwnProperty('given_name')) {
                user.firstname = payload.given_name;
            }
            if (payload.hasOwnProperty('family_name')) {
                user.lastname = payload.family_name;
            }
            if (payload.hasOwnProperty('name')) {
                user.displayName = payload.name;
            } else {
                user.displayName = user.firstname;
                if (isNullOrWhiteSpace(user.displayName)) {
                    user.displayName = user.lastname;
                } else {
                    if (!isNullOrWhiteSpace(user.lastname)) {
                        user.displayName += ' ' + user.lastname;
                    }
                }
            }
            if (payload.hasOwnProperty('cognito:groups')) {
                user.groups = payload['cognito:groups'];
            }
            if (payload.hasOwnProperty('cognito:roles')) {
                user.userRoles = payload['cognito:roles'];
            }
            if (payload.hasOwnProperty('phone_number')) {
                user.phoneNumber = payload.phone_number;
            }
            if (payload.hasOwnProperty('phone_number_verified')) {
                user.isPhoneVerified = payload.phone_number_verified;
            }
            if (payload.hasOwnProperty('picture')) {
                user.picture = payload.picture;
            } else {
                user.picture = "assets/img/avatars/sunny.png";
            }
        }
        return user;
    }

    public login(options?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (options && isNullOrWhiteSpace(options.username) && isNullOrWhiteSpace(options.password)) {
                reject({ message: "Provide username and password.", error: new Error("Provide username and password.") });
                return;
            }
            const username = options.username;
            const password = options.password;
            const authenticationData = {
                Username: username,
                Password: password,
                Scope: this.m_environment.PHARAOH_API_SCOPE
            };
            const authenticationDetails = new AuthenticationDetails(authenticationData);

            const cognitoUser = this.getUserFromCurrentUserPool(username);
            this.m_cognitoUser = cognitoUser;

            console.info("AuthenticationCognitoService: Params set...Authenticating the user");
            // console.log("AuthenticationCognitoService: config is ", AWS.config);
            cognitoUser.authenticateUser(authenticationDetails, {
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    // User was signed in, but a new password is required
                    resolve({ userAttributes, requiredAttributes, user: cognitoUser });
                },
                onSuccess: (result: any) => {

                    console.info("In authenticateUser onSuccess callback");

                    if (result && result.idToken && result.idToken.payload) {
                        this.m_tokenSvc.idToken = result.idToken.jwtToken;
                        this.m_user = this.convertIdTokenPayloadToUserInfo(result.idToken.payload);
                        this.m_userSvc.user$.next(this.m_user);
                        if (result.accessToken && result.accessToken.jwtToken) {
                            this.m_tokenSvc.token = result.accessToken.jwtToken;
                            resolve(true);
                            return;
                        }
                    }
                    reject({ message: "The response from AWS is invalid!" })
                },
                onFailure: function (err) {
                    let message = err;
                    if (typeof err === 'object') {
                        if (err.code) {
                            switch (err.code) {
                                case "InvalidParameterException":
                                    message = err.message;
                                    break;
                                default:
                                    message = 'Invalid Username or Password.';
                                    break;
                            }
                        } else {
                            message = 'Invalid Username or Password.';
                        }
                    }
                    reject({ message: message, error: err });
                },
            });
        });
    }

    completeLogin(options?: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log("AuthenticationCognitoService: Logging out");
            const poolData = {
                UserPoolId: this.m_environment.COGNITO_USERPOOL_ID
                , ClientId: this.m_environment.COGNITO_CLIENT_ID
            };
            const cognitoUserpool = new CognitoUserPool(poolData);
            if (cognitoUserpool == null) {
                console.warn("AuthenticationCognitoService: can't retrieve current user pool");
                reject({ message: "AuthenticationCognitoService: can't retrieve current user pool.", error: new Error("Can't retrieve current user pool.") });
                return;
            }
            const cognitoUser = cognitoUserpool.getCurrentUser();
            if (cognitoUser != null) {
                cognitoUser.signOut();
                this.m_user = null;
                resolve({ message: "Success" });
            } else {
                reject({ message: "No user to sign out.", error: new Error("No user to sign out.") });
            }
        });
    }
    isAuthenticated(): Promise<boolean> {
        return new Promise(resolve => {
            const poolData = {
                UserPoolId: this.m_environment.COGNITO_USERPOOL_ID
                , ClientId: this.m_environment.COGNITO_CLIENT_ID
            };
            const cognitoUserpool = new CognitoUserPool(poolData);
            if (cognitoUserpool == null) {
                console.warn("AuthenticationCognitoService: can't retrieve current user pool");
                return resolve(false);
            }
            const cognitoUser = cognitoUserpool.getCurrentUser();
            this.m_cognitoUser = cognitoUser;

            if (cognitoUser != null) {
                cognitoUser.getSession((err: any, session: any) => {
                    if (err) {
                        console.log(`AuthenticationCognitoService: Couldn't get the session: ${err}`, err.stack);
                        return resolve(false);
                    }
                    else {
                        const valid = session.isValid();
                        console.log(`AuthenticationCognitoService: Session is ${valid ? 'valid' : 'invalid'}.`);
                        if (valid) {
                            this.m_tokenSvc.token = session.accessToken.jwtToken;
                            this.m_tokenSvc.idToken = session.idToken.jwtToken;

                            /**
                             * Apparently CognitoUser.getSession will return a valid session for as long
                             * as the refresh token hasn't expired. We may want to expire it earlier for various
                             * security reasons since the access tokens will refresh whenever the page loads.
                             * Maybe have a Public PC vs. Private PC sign-in option?
                             * The commented out code below is for reference later if we want to track token
                             * expiry manually later.
                             */
                            // if (session.accessToken && session.accessToken.payload) {
                            //     const now = Math.floor(Date.now() / 1000);
                            //     if (now > session.accessToken.payload.exp) {
                            //         this.m_tokenSvc.dumpToken(null);
                            //         return resolve(false);
                            //     } else {
                            //         // Token is good - should we refresh it?
                            //         // Is it half past expiration time?
                            //         const halfway = Math.floor((session.accessToken.payload.exp - session.accessToken.payload.iat) / 2);
                            //         const nowRelativeToIssueTime = now - session.accessToken.payload.iat;
                            //         if (nowRelativeToIssueTime > halfway) {
                            //             // Now is a good enough time to refresh
                            //             return cognitoUser.refreshSession(session.refreshToken, (err, session) => {
                            //                 console.log("Refresh Error: ", err);
                            //                 console.log("Refresh Session: ", session);
                            //                 this.m_tokenSvc.token = session.accessToken.jwtToken;
                            //                 resolve(true);
                            //             });
                            //         }
                            if (this.m_user === null) {
                                this.m_user = this.convertIdTokenPayloadToUserInfo(session.idToken.payload);
                                this.m_userSvc.user$.next(this.m_user);
                            }
                            return resolve(true);
                            //     }
                            // }
                        }
                        return resolve(false);
                    }
                });
            } else {
                console.warn("AuthenticationCognitoService: can't retrieve the current user");
                return resolve(false);
            }
        });
    }
    forgotPassword(options?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (options && !isNullOrWhiteSpace(options.username)) {
                const username = options.username;
                if (!isNullOrWhiteSpace(options.verificationCode)) {
                    if (isNullOrWhiteSpace(options.password)) {
                        reject({ message: "Provide the new password.", error: new Error("Provide the new password.") });
                        return;
                    }
                    const verificationCode = options.verificationCode;
                    const password = options.password;
                    //Verification code requested - now confirming
                    const cognitoUser = this.getUserFromCurrentUserPool(username);
                    cognitoUser.confirmPassword(verificationCode, password, {
                        onSuccess: function () {
                            resolve({ message: "Success" });
                        },
                        onFailure: function (err) {
                            reject({ message: err.message, error: err });
                        }
                    });
                } else {
                    //Initiating forgot password workflow - sending verification code
                    const cognitoUser = this.getUserFromCurrentUserPool(username);
                    cognitoUser.forgotPassword({
                        onSuccess: function (result) {
                            resolve({ message: "Success", result: result });
                        },
                        onFailure: function (err) {
                            reject({ message: err.message, error: err });
                        },
                        inputVerificationCode: function (data) {
                            resolve({ message: "Verification code sent.", result: data });
                        }
                    });
                }
            } else {
                reject({ message: "Provide a username." });
            }
        });
    }

    //Requests Verification code from AWS
    sendVerificationCode(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.m_cognitoUser.getAttributeVerificationCode('email', {
                onSuccess: (result) => {
                    resolve(result);
                },
                onFailure: (err) => {
                    console.error('Failed to send verification code:', err);
                    reject(err);
                },
            });
        });
    }

    //Submits user's new password to AWS
    changePassword(options?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!options || !options.newPassword) {
                reject(new Error('No user data found'));
                return;
            }
            this.m_cognitoUser.completeNewPasswordChallenge(options.newPassword, {}, {
                onSuccess: (result) => {
                    this.m_cognitoUser.getSession((err, session) => {
                        if (err) {
                            console.error(err);
                            reject(new Error('Error retrieving session'));
                            return;
                        }
                        if (session && session.isValid()) {
                            resolve(result);
                        } else {
                            reject(new Error('Session is not valid'));
                        }
                    });
                },
                onFailure: (err) => {
                    console.error('Error changing password:', err);
                    reject(err);
                }
            });
        });
    }

    //Submits user's verification code to AWS
    verifyUserAttribute(code: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!code) {
                reject(new Error('Verification code not sent'));
                return;
            }

            this.m_cognitoUser.verifyAttribute('email', code, {
                onSuccess: (result) => {
                    resolve(result);
                },
                onFailure: (err) => {
                    console.error('Failed to verify email.', err);
                    reject(err);
                }
            });
        });
    }

    //Checks if the user has a verified e-mail
    checkEmailVerified(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.m_cognitoUser.getUserAttributes((err, attributes) => {
                if (err) {
                    console.error('Error fetching user attributes:', err);
                    reject(err);
                } else {
                    const emailAttribute = attributes.find(attr => attr.Name === 'email_verified');
                    const emailVerified = emailAttribute ? emailAttribute.Value === 'true' : false;
                    resolve(emailVerified);
                }
            });
        });
    }
}