import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";

export enum PaymentType {
    UNKNOWN
    , CHECK
    , CREDIT_CARD
    , MANUAL_CREDIT_CARD
    , WIRE_TRANSFER
    , ACCOUNT_CREDIT
    
    , INVALID
}

export class PaymentAllocation {
    id: number;
    amount: number;
    invoicePeriod: string;
    invoiceBalance: number;
    paymentId: number;
    invoiceId: number;
}

export class PaymentModel {
    id: number;
    createdDate: Date;
    balance: number;
    amount: number;
    accountId: number;
    account: Account;
    paymentDate: Date;
    type: PaymentType;
    ccType: string;
    ccFirstFour: string;
    ccLastFour: string;
    ccAvsResponse: string;
    ccApprovalCode: string;
    checkDate: Date;
    checkNumber: string;
    wireTransferReferenceNumber: string;
    accountCreditReason: string;

    allocations: Array<PaymentAllocation>;
}

export class PaymentMethodModel {
    id: number;
}


export class GetListPaymentsResponse extends PaginatedResponseModel<PaymentModel> { }
export class GetListPaymentsByAccountResponse extends PaginatedResponseModel<PaymentModel> { }
export class GetPaymentResponse extends PaymentModel { } 
export class CreatePaymentResponse extends PaymentModel { } 
export class PatchPaymentResponse extends PaymentModel { } 
export class DeletePaymentResponse { } 