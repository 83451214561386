import { Injectable, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";

@Injectable()
export class ActiveRouteDataService implements OnDestroy {
    private m_ngUnsubscribe = new Subject();
    private m_dataSubject = new BehaviorSubject<any>({});
    public data$ = this.m_dataSubject.asObservable();

    constructor(router: Router
        , activatedRoute: ActivatedRoute) {
        router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.m_ngUnsubscribe)).subscribe(() => {
            let active = activatedRoute;
            while (active.firstChild) {
                active = active.firstChild;
            };
            const data = active.snapshot.data;
            this.m_dataSubject.next(data);
        });
    }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }
}
