import { OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IBaseStorageService, IStorageInitializeOptions, IStorageOptions } from "@app/ultisat/models";
import { ActiveRouteDataService } from "../active-route-data.service";

export abstract class BaseStorageService implements IBaseStorageService, OnDestroy {
    protected m_ngUnsubscribe = new Subject();
    protected m_initializingSubject = new BehaviorSubject<boolean>(false);
    public initializing$: Observable<boolean> = this.m_initializingSubject.asObservable();
    protected m_readySubject = new BehaviorSubject<boolean>(false);
    public ready$: Observable<boolean> = this.m_readySubject.asObservable();
    protected m_loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$: Observable<boolean> = this.m_loadingSubject.asObservable();
    protected m_storingSubject = new BehaviorSubject<boolean>(false);
    public storing$: Observable<boolean> = this.m_storingSubject.asObservable();
    protected m_appName: string = "";
    protected m_appVersion: string = "0.0.0.0";    
    protected m_routeName: string;
    protected m_routePageTitle: string;
    protected m_routeInfoSet = false;

    constructor(private m_activeRouteDataSvc: ActiveRouteDataService) {
        this.m_activeRouteDataSvc.data$.pipe(takeUntil(this.m_ngUnsubscribe)).subscribe(data => {
            if (data) {
                this.m_routeName = data.name == null ? null : data.name.replace(/\ /g, '_');
                this.m_routePageTitle = data.pageTitle == null ? null : data.pageTitle.replace(/\ /g, '_');
                this.m_routeInfoSet = true;
            }
            this.m_readySubject.next(this.m_routeInfoSet && !this.m_initializingSubject.value);
        });
    }

    public ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public isInitialized(): boolean {
        return this.m_initializingSubject.value;
    };
    abstract initialize(options?: IStorageInitializeOptions): Observable<boolean>;
    abstract store<T>(key: string, value: T, options?: IStorageOptions): Observable<boolean>;
    abstract fetch<T>(key: string, options?: IStorageOptions): Observable<T>;
    abstract subscribe<T>(key: string, options?: IStorageOptions): Observable<T>;
    abstract remove(key: string, options?: IStorageOptions): Observable<boolean>;
    abstract clear(options?: IStorageOptions): Observable<boolean>;
}

export abstract class UserPreferencesService extends BaseStorageService implements IBaseStorageService { }
export abstract class BrowserStorageService extends BaseStorageService implements IBaseStorageService { }