import { ReleaseModel } from "./release.model";

export class ReleaseNoteModel {
    public id: number;
    public type: string;
    public text: string;
}

export class ReleaseNotesModel extends ReleaseModel {
    public newFeatures: Array<ReleaseNoteModel> = new Array<ReleaseNoteModel>();
    public changes: Array<ReleaseNoteModel> = new Array<ReleaseNoteModel>();
    public bugFixes: Array<ReleaseNoteModel> = new Array<ReleaseNoteModel>();
    public known: Array<ReleaseNoteModel> = new Array<ReleaseNoteModel>();

    constructor(release: ReleaseModel, notes: Array<ReleaseNoteModel>) {
        super(release);
        for (const note of notes) {
            switch (note.type) {
                case "NEW":
                    this.newFeatures.push(note);
                    break;
                case "CHANGE":
                    this.changes.push(note);
                    break;
                case "BUGFIX":
                    this.bugFixes.push(note);
                    break;
                case "KNOWN":
                    this.known.push(note);
                    break;
            }
        }
    }
}
