import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import * as ultiModels from './ultisat/models';
import { UltisatModule } from './ultisat/ultisat.module';
import { EnvConfigService } from './env-config.service';

// Application wide providers
export const APP_PROVIDERS = [
  { provide: 'LOCALSTORAGE', useFactory: getLocalStorage }
  , { provide: 'SESSIONSTORAGE', useFactory: getSessionStorage }
  , { provide: 'WINDOW', useFactory: getWindow }
  , EnvConfigService
  , { provide: ultiModels.AppConfigurationService, useExisting: EnvConfigService }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    UltisatModule.forRoot(),
  ],
  providers: [
    ...APP_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function getLocalStorage() {
  return (typeof window !== "undefined") ? window.localStorage : null;
}

export function getSessionStorage() {
  return (typeof window !== "undefined") ? window.sessionStorage : null;
}

export function getWindow() {
  return (typeof window !== "undefined") ? window : null;
}