import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService, AuthorizationService, NotificationService, CapabilityPharaoh } from "@app/ultisat/models";

@Injectable()
export class RouteGuardPharaohService implements CanActivate {
    constructor(private m_router: Router
        , private m_authenticationSvc: AuthenticationService
        , private m_authorizationSvc: AuthorizationService
        , private m_notifySvc: NotificationService) { }

    private hasRequiredCapabilities(capabilitiesRequired: Array<CapabilityPharaoh>): boolean {
        if (Array.isArray(capabilitiesRequired)) {
            capabilitiesRequired.forEach(capability => {
                if (!this.m_authorizationSvc.hasCapability(capability)) {
                    return false;
                }
            });
            return true;
        } else {
            const testType = typeof (capabilitiesRequired);
            switch (testType) {
                case 'string':
                    return this.m_authorizationSvc.hasCapability(capabilitiesRequired);
                case 'undefined':
                    return true;
                default:
                    console.error("Invalid Capability Format!", JSON.stringify(capabilitiesRequired, null, 2));
                    throw new Error("Invalid Capability Format!");
            }
        }
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Promise(resolve => {
            this.m_authenticationSvc.isAuthenticated().then(isAuthenticated => {
                if (isAuthenticated) {
                    this.m_authorizationSvc.initializePermissions().then(response => {
                        const authorized = this.hasRequiredCapabilities(route.data['auth']);
                        if (!authorized) {
                            this.m_notifySvc.notifyWarning('Unauthorized', 'You do not have permission to see this page.');
                        }
                        resolve(authorized);
                    }).catch(error => {
                        console.error("Auth Error: ", error);
                        this.m_notifySvc.messageError("Authentication Error", error);
                        this.m_router.navigate(['auth/login']);
                        resolve(false);
                    });
                } else {
                    this.m_router.navigate(['auth/login']);
                    resolve(false);
                }
            }).catch(error => {
                console.error("Auth Error: ", error);
                this.m_notifySvc.messageError("Authentication Error", error);
                this.m_router.navigate(['auth/login']);
                resolve(false);
            });
        });
    }
}