import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BootstrapModule } from '@app/shared/bootstrap.module';
import { SmartadminFormsLiteModule } from '@app/shared/forms/smartadmin-forms-lite.module';
import { SmartadminFormsModule } from '@app/shared/forms/smartadmin-forms.module';
import { AuthenticationService, AuthorizationService, NotificationService, TranslationService } from './models';
import { AuthenticationCognitoService, AuthorizationPharaohService, BrowserStorageService, NotificationSmartboxService, OAuthHeaderInterceptor, RouteGuardPharaohService, LocalStorageService, TranslationI18nService, UserPreferencesService, apiServices, apiServicesForRoot, ActiveRouteDataService } from './services';
import { PipesModule } from './pipes/pipes.module';
import * as ultiComps from './components';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        BootstrapModule,
        SmartadminFormsLiteModule,
        SmartadminFormsModule,

        PipesModule,
    ],
    providers: [
        ...apiServices
    ],
    declarations: [...ultiComps.components]
    , entryComponents: [...ultiComps.entryComponents]
    , exports: [
        PipesModule
        , ...ultiComps.components
    ]
})
export class UltisatModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: UltisatModule
            , providers: [
                { provide: HTTP_INTERCEPTORS, useClass: OAuthHeaderInterceptor, multi: true }
                , RouteGuardPharaohService
                , ...apiServicesForRoot
                , AuthenticationCognitoService
                , { provide: AuthenticationService, useExisting: AuthenticationCognitoService }
                , AuthorizationPharaohService
                , { provide: AuthorizationService, useExisting: AuthorizationPharaohService }
                , NotificationSmartboxService
                , { provide: NotificationService, useExisting: NotificationSmartboxService }
                , TranslationI18nService
                , { provide: TranslationService, useExisting: TranslationI18nService }
                , ActiveRouteDataService
                , LocalStorageService
                , { provide: BrowserStorageService, useExisting: LocalStorageService }
                , { provide: UserPreferencesService, useExisting: LocalStorageService }
            ]
        }
    }
}
