import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Product, AppConfigurationService, CreateProductResponse, DeleteProductResponse, GetProductResponse, GetListProductsResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchProductResponse, GetListProductPricingResponse, ProductPricing } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class ProductService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createProduct = new HttpRequestStream<Product>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newProduct$ = this.m_createProduct.response$;
    public isCreateProductLoading$ = this.m_createProduct.isLoading$;

    private m_getProducts = new HttpPaginatedRequestStream<Product>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public products$ = this.m_getProducts.response$;
    public isProductsLoading$ = this.m_getProducts.isLoading$;

    private m_getProduct = new HttpRequestStream<Product>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public product$ = this.m_getProduct.response$;
    public isProductLoading$ = this.m_getProduct.isLoading$;

    private m_patchProduct = new HttpRequestStream<Product>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchProduct$ = this.m_patchProduct.response$;
    public isPatchProductLoading$ = this.m_patchProduct.isLoading$;

    private m_deleteProduct = new HttpRequestStream<DeleteProductResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteProduct$ = this.m_deleteProduct.response$;
    public isDeleteProductLoading$ = this.m_deleteProduct.isLoading$;

    private m_deleteProducts = new HttpRequestStream<DeleteProductResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteProducts$ = this.m_deleteProducts.response$;
    public isDeleteProductsLoading$ = this.m_deleteProducts.isLoading$;

    private m_getProductPricing = new HttpPaginatedRequestStream<ProductPricing>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public productPricing$ = this.m_getProductPricing.response$;
    public isProductPricingLoading$ = this.m_getProductPricing.isLoading$;

    constructor(
        private m_http: HttpClient,
        private m_appConfig: AppConfigurationService,
        private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createProduct(product: Product): Observable<CreateProductResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Product/`;
        return this.m_createProduct.send({ url: url, method: HttpMethod.POST, body: product });
    }

    public getProducts(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListProductsResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Product/${getPaginatedRequestUrl(options)}`;
        return this.m_getProducts.send({ url: url, isPoll: isPoll });
    }

    public getProduct(id: number, isPoll = false): Observable<GetProductResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Product/${id}/`;
        return this.m_getProduct.send({ url: url, isPoll: isPoll });
    }

    public patchProduct(id: number, obj: any): Observable<PatchProductResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Product/${id}/`;
        return this.m_patchProduct.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteProduct(id: number): Observable<DeleteProductResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Product/${id}/`;
        return this.m_deleteProduct.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteProducts(ids: Array<number>): Observable<DeleteProductResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Product/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteProducts.send({ url: url, method: HttpMethod.DELETE });
    }

    public getProductPricing(accountId: number, parentId?: number, options?: IPaginatedRequestModel, isPoll = false): Observable<GetListProductPricingResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${accountId}/products/pricing/${parentId ? parentId + '/' : ''}${getPaginatedRequestUrl(options)}`;
        return this.m_getProductPricing.send({ url: url, isPoll: isPoll });
    }
}
