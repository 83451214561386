import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JobInfo, JobStatus } from '../../models';

@Component({
    selector: 'ph-jobinfo',
    templateUrl: './jobinfo.component.html',
})
export class JobInfoComponent {
    private m_jobInfo: JobInfo = null;
    public isSuccessState = false;
    public isErrorState = true;
    public isFinished = false;

    @Output() public close = new EventEmitter();
 
    @Input()
    set jobInfo(value: JobInfo) {
        this.m_jobInfo = value;
        if (null != this.m_jobInfo) {
            this.isSuccessState = this.m_jobInfo.status == JobStatus.COMPLETED;
            this.isErrorState = this.m_jobInfo.status == JobStatus.ERROR
                || this.m_jobInfo.status == JobStatus.INVALID
                || this.m_jobInfo.status == JobStatus.TIMEDOUT
                || this.m_jobInfo.status == JobStatus.UNKNOWN;
            this.isFinished = this.isSuccessState || this.isErrorState;
        }
    }
    get jobInfo(): JobInfo {
        return this.m_jobInfo;
    }
}
