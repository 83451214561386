import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { InvoiceModel, PaymentAllocation, PaymentModel, PaymentType } from '../../models';
import { BootstrapValidatorDirective } from '@app/shared/forms/validation/bootstrap-validator.directive';

interface IInvoiceAllocationPrep {
    selected: boolean;
    amount: number;
    invoice: InvoiceModel;
}

@Component({
    selector: 'ph-payment',
    templateUrl: './payment.component.html',
})
export class PaymentComponent implements OnInit, OnChanges {
    @ViewChild(BootstrapValidatorDirective) public paymentForm: BootstrapValidatorDirective;
    @Input() public invoices: Array<InvoiceModel> = [];
    @Output() public addPayment = new EventEmitter<PaymentModel>();
    @Output() public cancel = new EventEmitter<void>();

    public invoiceAllocations: Array<IInvoiceAllocationPrep> = [];
    public paymentTypes = PaymentType;
    public paymentType: PaymentType = PaymentType.MANUAL_CREDIT_CARD;
    public payment = new PaymentModel();

    public paymentDate: Date = new Date();
    public checkDate: Date = new Date();
    public paymentTime: string = null;
    public totalAllocationAmount: number = 0;

    public select2PaymentTypes: any = {
        placeholder: "Select Payment Type...",
        width: '100%',
    };

    public ngOnInit(): void {
        this.payment.amount = 0;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.invoices) {
            this.invoices = changes.invoices.currentValue;
            this.invoiceAllocations = [];
            this.invoices.forEach(invoice => { 
                this.invoiceAllocations.push({
                    selected: false,
                    amount: 0,
                    invoice: invoice,
                });
            });
        }
    }

    public calculateInvoiceAllocationBalance(): void {
        this.totalAllocationAmount = 0;
        this.invoiceAllocations.forEach(allocation => {
            if (allocation.selected === true) {
                this.totalAllocationAmount += allocation.amount;
            }
        });
    }

    public setFullBalanceToAmount(): void {
        this.payment.amount = this.totalAllocationAmount;
    }

    public onPaymentTypeChange($event: any): void {
        this.paymentType = Number($event.id) as PaymentType;
        this.paymentForm.refresh();
    }

    public allocateFullBalance($allocation: IInvoiceAllocationPrep): void {
        $allocation.amount = $allocation.invoice.balance;
        $allocation.selected = true;
        this.calculateInvoiceAllocationBalance();
    }

    public onAddPayment(): void {
        this.payment.balance = this.payment.amount - this.totalAllocationAmount;
        if (this.payment.balance >= 0 && this.payment.amount > 0) {
            this.payment.type = this.paymentType;
            this.payment.paymentDate = new Date(this.paymentDate);
            if (this.paymentType === PaymentType.MANUAL_CREDIT_CARD) {
                const timeParts = this.paymentTime != null ? this.paymentTime.split(':') : ["0", "0"];
                this.payment.paymentDate.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]));
            } else if (this.paymentType === PaymentType.CHECK) {
                this.payment.checkDate = new Date(this.checkDate);
            }            
            
            this.payment.allocations = [];
            this.invoiceAllocations.forEach(allocation => { 
                if (allocation.selected) {
                    const alloc = new PaymentAllocation();
                    alloc.amount = allocation.amount;
                    alloc.invoiceId = allocation.invoice.id;
                    alloc.invoicePeriod = allocation.invoice.chargePeriod;
                    alloc.invoiceBalance = allocation.invoice.balance - alloc.amount;
                    this.payment.allocations.push(alloc);
                    this.payment.accountId = allocation.invoice.accountId;
                }
            });
            if (this.payment.allocations.length == 0) {
                return; //error ?
            }
            
            this.addPayment.emit(this.payment);
        } // else error?
    }
}
