﻿import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";
import { UserRoleModel } from "./user-role.model";

export class UserModel {
    public id: number;
    public username: string;
    public password?: string;
    public displayName?: string;
    public firstname?: string;
    public lastname?: string;
    public email?: string;
    public isEmailVerified?: boolean;
    public phoneNumber?: string;
    public isPhoneVerified?: boolean;
    public isActive?: boolean;
    public accountId?: number;
    public account?: Account;
    public groups?: Array<string>;
    public userRoles?: Array<UserRoleModel> = [];
    // public roles?: Array<string>;
    public picture?: string;
}

export class GetListUsersResponse extends PaginatedResponseModel<UserModel> { }
export class GetUserResponse extends UserModel { }
export class CreateUserResponse extends UserModel { }
export class PatchUserResponse extends UserModel { }
export class DeleteUserResponse { } 