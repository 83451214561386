﻿import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../models';

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform {

    constructor(public translateService: TranslationService) { }

    transform(phrase: any, args?: any): any {
        return this.translateService.getTranslation(phrase);
    }

}
