import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ReferenceValueModel, AppConfigurationService, CreateReferenceValueResponse, DeleteReferenceValueResponse, GetReferenceValueResponse, GetListReferenceValuesResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchReferenceValueResponse, PaginatedResponseModel } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, isNullOrWhiteSpace } from "@app/ultisat/utility";


@Injectable()
export class ReferenceValueService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createReferenceValue = new HttpRequestStream<ReferenceValueModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newReferenceValue$ = this.m_createReferenceValue.response$;
    public isCreateReferenceValueLoading$ = this.m_createReferenceValue.isLoading$;

    private m_getReferenceValues = new HttpPaginatedRequestStream<ReferenceValueModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public referenceValues$ = this.m_getReferenceValues.response$;
    public isReferenceValuesLoading$ = this.m_getReferenceValues.isLoading$;

    private m_getReferenceValueDistinctKeys = new HttpPaginatedRequestStream<string>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public referenceValueKeys$ = this.m_getReferenceValueDistinctKeys.response$;
    public isReferenceValueKeysLoading$ = this.m_getReferenceValueDistinctKeys.isLoading$;

    private m_getFilteredReferenceValues = new HttpPaginatedRequestStream<ReferenceValueModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public referenceValuesFiltered$ = this.m_getFilteredReferenceValues.response$;
    public isReferenceValuesFilteredLoading$ = this.m_getFilteredReferenceValues.isLoading$;

    private m_getReferenceValue = new HttpRequestStream<ReferenceValueModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public referencevalue$ = this.m_getReferenceValue.response$;
    public isReferenceValueLoading$ = this.m_getReferenceValue.isLoading$;

    private m_patchReferenceValue = new HttpRequestStream<ReferenceValueModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchReferenceValue$ = this.m_patchReferenceValue.response$;
    public isPatchReferenceValueLoading$ = this.m_patchReferenceValue.isLoading$;

    private m_updateReferenceValue = new HttpRequestStream<ReferenceValueModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateReferenceValue$ = this.m_updateReferenceValue.response$;
    public isUpdateReferenceValueLoading$ = this.m_updateReferenceValue.isLoading$;

    private m_deleteReferenceValue = new HttpRequestStream<DeleteReferenceValueResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteReferenceValue$ = this.m_deleteReferenceValue.response$;
    public isDeleteReferenceValueLoading$ = this.m_deleteReferenceValue.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createReferenceValue(referencevalue: ReferenceValueModel): Observable<CreateReferenceValueResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ReferenceValue/`;
        return this.m_createReferenceValue.send({ url: url, method: HttpMethod.POST, body: referencevalue });
    }

    public getReferenceValues(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListReferenceValuesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ReferenceValue/${getPaginatedRequestUrl(options)}`;
        return this.m_getReferenceValues.send({ url: url, isPoll: isPoll });
    }

    public getReferenceValueDistinctKeys(options?: IPaginatedRequestModel, isPoll = false): Observable<PaginatedResponseModel<string>> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ReferenceValue/Keys/${getPaginatedRequestUrl(options)}`;
        return this.m_getReferenceValueDistinctKeys.send({ url: url, isPoll: isPoll });
    }

    public getFilteredReferenceValues(key: string, value?: string, options?: IPaginatedRequestModel, isPoll = false): Observable<GetListReferenceValuesResponse> {
        let url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ReferenceValue/Keys/${key}/${getPaginatedRequestUrl(options)}`;
        if (!isNullOrWhiteSpace(value)) {
            url += `&value=${value}`;
        }
        return this.m_getReferenceValues.send({ url: url, isPoll: isPoll });
    }

    public getReferenceValue(id: number, isPoll = false): Observable<GetReferenceValueResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ReferenceValue/${id}/`;
        return this.m_getReferenceValue.send({ url: url, isPoll: isPoll });
    }

    public updateReferenceValue(id: number, obj: any): Observable<PatchReferenceValueResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ReferenceValue/${id}/`;
        return this.m_updateReferenceValue.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public patchReferenceValue(id: number, obj: any): Observable<PatchReferenceValueResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ReferenceValue/${id}/`;
        return this.m_patchReferenceValue.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteReferenceValue(id: number): Observable<DeleteReferenceValueResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ReferenceValue/${id}/`;
        return this.m_deleteReferenceValue.send({ url: url, method: HttpMethod.PATCH });
    }
}
