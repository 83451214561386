import { PaginatedResponseModel } from "../paginated-response.model";

export class CountryArea {
    id: number;
    countryId: number;
    country: CountryModel;
    code: string;
    name: string;
}

export class CountryAlias {
    id: number;
    countryId: number;
    country: CountryModel;
    alias: string;
}

export class CountryModel {
    id: number;
    name: string;
    iso2: string;
    iso3: string;
    isoNumeric: string;
    iso3116_2: string;
    regionCode: string;
    subRegionCode: string;
    dialCode: string

    aliases: Array<CountryAlias> = [];
    areas: Array<CountryArea> = [];
}

export class GetListCountriesResponse extends PaginatedResponseModel<CountryModel> { }
// export class GetListCountrysResponse extends Array<CountryModel> { }
export class GetCountryResponse extends CountryModel { } 
export class CreateCountryResponse extends CountryModel { } 
export class PatchCountryResponse extends CountryModel { } 
export class DeleteCountryResponse { } 