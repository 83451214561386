export const environment = {
  authentication: true,
  authorization: true,
  production: false,
  configFile: './assets/config/config.aws-dev.json',

  firebase: {
  },

  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  }

};