import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ContactModel, AppConfigurationService, CreateContactResponse, DeleteContactResponse, GetContactResponse, GetListContactsResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchContactResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";

@Injectable()
export class ContactService implements OnDestroy {
    private m_ngUnsubscribe = new Subject();

    private m_createContact = new HttpRequestStream<ContactModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newContact$ = this.m_createContact.response$;
    public isCreateContactLoading$ = this.m_createContact.isLoading$;

    private m_getContacts = new HttpPaginatedRequestStream<ContactModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public contacts$ = this.m_getContacts.response$;
    public isContactsLoading$ = this.m_getContacts.isLoading$;

    private m_getContact = new HttpRequestStream<ContactModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public contact$ = this.m_getContact.response$;
    public isContactLoading$ = this.m_getContact.isLoading$;

    private m_patchContact = new HttpRequestStream<ContactModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchContact$ = this.m_patchContact.response$;
    public isPatchContactLoading$ = this.m_patchContact.isLoading$;

    private m_updateContact = new HttpRequestStream<ContactModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateContact$ = this.m_updateContact.response$;
    public isUpdateContactRoleLoading$ = this.m_updateContact.isLoading$;

    private m_deleteContact = new HttpRequestStream<DeleteContactResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteContact$ = this.m_deleteContact.response$;
    public isDeleteContactLoading$ = this.m_deleteContact.isLoading$;

    private m_deleteContacts = new HttpRequestStream<DeleteContactResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteContacts$ = this.m_deleteContacts.response$;
    public isDeleteContactsLoading$ = this.m_deleteContacts.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createContact(contact: ContactModel): Observable<CreateContactResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Contact/`;
        return this.m_createContact.send({ url: url, method: HttpMethod.POST, body: contact });
    }

    public getContacts(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListContactsResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Contact/${getPaginatedRequestUrl(options)}`;
        return this.m_getContacts.send({ url: url, isPoll: isPoll });
    }

    public getContact(id: number, isPoll = false): Observable<GetContactResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Contact/${id}/`;
        return this.m_getContact.send({ url: url, isPoll: isPoll });
    }

    public patchContact(id: number, obj: any): Observable<PatchContactResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Contact/${id}/`;
        return this.m_patchContact.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public updateContact(id: number, obj: any): Observable<PatchContactResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Contact/${id}/`;
        return this.m_updateContact.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public deleteContact(id: number): Observable<DeleteContactResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Contact/${id}/`;
        return this.m_deleteContact.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteContacts(ids: Array<number>): Observable<DeleteContactResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Contact/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteContacts.send({ url: url, method: HttpMethod.DELETE });
    }
}
