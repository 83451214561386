

import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginInfoComponent } from "./login-info/login-info.component";
import { LogoutComponent } from "./logout/logout.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

@NgModule({
  imports: [CommonModule, BsDropdownModule.forRoot()],
  declarations: [LoginInfoComponent, LogoutComponent],
  exports: [LoginInfoComponent, LogoutComponent]
})
export class UserModule { }
