import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, INotificationsConfiguration } from './ultisat/models';
import { environment } from '@env/environment';

@Injectable()
export class EnvConfigService extends AppConfigurationService {
    public PHARAOH_API_BASE_URL: string;
    public DEFAULT_POLL_RATE: number;
    public API_THROTTLE: number;
    public notifications?: INotificationsConfiguration;
    public authentication?: any;
    constructor(private http: HttpClient) {
        super();
        this.getConfig();
    }

    public async getConfig() {
        // let data = await this.http.get('assets/config/config.json').toPromise();
        let data = await this.http.get(environment.configFile).toPromise();
        if (data['PHARAOH_API_BASE_URL']) { this.PHARAOH_API_BASE_URL = data['PHARAOH_API_BASE_URL']; }
        if (data['DEFAULT_POLL_RATE']) { this.DEFAULT_POLL_RATE = data['DEFAULT_POLL_RATE']; }
        if (data['notifications']) { this.notifications = data['notifications']; }
        if (data['authentication']) { this.authentication = data['authentication']; }
        if (data['API_THROTTLE']) { this.API_THROTTLE = data['API_THROTTLE']; }
    }
}