import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { InvoiceModel, AppConfigurationService, DeleteInvoiceResponse, GetInvoiceResponse, GetListInvoicesResponse, HttpMethod, IPaginatedRequestModel, NotificationService, GetListInvoicesByAccountResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class InvoiceService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_getInvoices = new HttpPaginatedRequestStream<InvoiceModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public invoices$ = this.m_getInvoices.response$;
    public isInvoicesLoading$ = this.m_getInvoices.isLoading$;

    private m_getInvoicesByAccount = new HttpPaginatedRequestStream<InvoiceModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public invoicesByAccount$ = this.m_getInvoicesByAccount.response$;
    public isInvoicesByAccountLoading$ = this.m_getInvoicesByAccount.isLoading$;

    private m_getInvoice = new HttpRequestStream<InvoiceModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public invoice$ = this.m_getInvoice.response$;
    public isInvoiceLoading$ = this.m_getInvoice.isLoading$;

    private m_deleteInvoice = new HttpRequestStream<DeleteInvoiceResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteInvoice$ = this.m_deleteInvoice.response$;
    public isDeleteInvoiceLoading$ = this.m_deleteInvoice.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public getInvoices(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListInvoicesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Invoice/${getPaginatedRequestUrl(options)}`;
        return this.m_getInvoices.send({ url: url, isPoll: isPoll });
    }

    public getInvoicesByAccount(accountId: number, options?: IPaginatedRequestModel, isPoll = false): Observable<GetListInvoicesByAccountResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${accountId}/Invoices/${getPaginatedRequestUrl(options)}`;
        return this.m_getInvoicesByAccount.send({ url: url, isPoll: isPoll });
    }

    public getInvoice(id: number, isPoll = false): Observable<GetInvoiceResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Invoice/${id}/`;
        return this.m_getInvoice.send({ url: url, isPoll: isPoll });
    }

    public deleteInvoice(id: number): Observable<DeleteInvoiceResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Invoice/${id}/`;
        return this.m_deleteInvoice.send({ url: url, method: HttpMethod.DELETE });
    }

}
