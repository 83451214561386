import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { BehaviorSubject, EMPTY, Observable, Subject } from "rxjs";
import { catchError, finalize, switchMap, takeUntil, throttleTime } from "rxjs/operators";
import { AppConfigurationService, HttpMethod, IUrlRequest, IXPaginationHeader, NotificationService, PaginatedResponseModel } from "@app/ultisat/models";
import { processError } from "./api";

export const HttpResponseHeader = {
    PAGINATION: "X-Pagination"
};

export class HttpRequestStream<T> {
    private m_httpSubject: Subject<IUrlRequest> = new Subject();
    private m_responseSubject = new Subject<T>();
    public response$: Observable<T> = this.m_responseSubject.asObservable();
    private m_loadingSubject = new BehaviorSubject(false);
    public isLoading$: Observable<boolean> = this.m_loadingSubject.asObservable();

    constructor(private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
        , private m_ngUnsubscribe: Subject<unknown>
        , private m_errTitle: string = "HTTP Request"
        , private m_errMessage: string = "There was an error processing your request") {
        this.m_httpSubject
            .pipe(throttleTime(this.m_appConfig.API_THROTTLE))
            .pipe(switchMap(request => {
                this.m_loadingSubject.next(true);
                let obs: Observable<HttpResponse<T>>;
                switch (request.method) {
                    case HttpMethod.DELETE:
                        obs = this.m_http.delete<T>(request.url, { observe: 'response' });
                        break;
                    case HttpMethod.PATCH:
                        obs = this.m_http.patch<T>(request.url
                            , request.body
                            , {
                                observe: 'response'
                                , headers: new HttpHeaders({ 'Content-Type': 'application/json-patch+json' })
                            });
                        break;
                    case HttpMethod.PUT:
                        obs = this.m_http.put<T>(request.url, request.body, { observe: 'response' });
                        break;
                    case HttpMethod.POST:
                        obs = this.m_http.post<T>(request.url, request.body, { observe: 'response' });
                        break;
                    default:
                        obs = this.m_http.get<T>(request.url, { observe: 'response' });
                        break;
                }
                return obs.pipe(
                    finalize(() => this.m_loadingSubject.next(false))
                    , catchError(error => {
                        if (!request.isPoll) {
                            processError(this.m_notificationSvc, this.m_errMessage, error, this.m_errTitle);
                        }
                        this.m_responseSubject.next(null);
                        return EMPTY;
                    }));
            })).pipe(takeUntil(this.m_ngUnsubscribe)).subscribe((response: HttpResponse<T>) => {
                this.m_responseSubject.next(response.body);
            });
    }

    public send(request: IUrlRequest): Observable<T> {
        this.m_httpSubject.next(request);
        return this.response$;
    }
}


export class HttpPaginatedRequestStream<T> {
    private m_httpSubject: Subject<IUrlRequest> = new Subject();
    private m_responseSubject = new Subject<PaginatedResponseModel<T>>();
    public response$: Observable<PaginatedResponseModel<T>> = this.m_responseSubject.asObservable();
    private m_loadingSubject = new BehaviorSubject(false);
    public isLoading$: Observable<boolean> = this.m_loadingSubject.asObservable();

    constructor(private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
        , private m_ngUnsubscribe: Subject<unknown>
        , private m_errTitle: string = "HTTP Request"
        , private m_errMessage: string = "There was an error processing your request") {
        this.m_httpSubject
            .pipe(throttleTime(this.m_appConfig.API_THROTTLE))
            .pipe(switchMap(request => {
                this.m_loadingSubject.next(true);                
                return this.m_http.get<Array<T>>(request.url, { observe: 'response' }).pipe(
                    finalize(() => this.m_loadingSubject.next(false))
                    , catchError(error => {
                        if (!request.isPoll) {
                            processError(this.m_notificationSvc, this.m_errMessage, error, this.m_errTitle);
                        }
                        this.m_responseSubject.next(null);
                        return EMPTY;
                    }));
            })).pipe(takeUntil(this.m_ngUnsubscribe)).subscribe((response: HttpResponse<Array<T>>) => {
                const paginationHeader: IXPaginationHeader = JSON.parse(response.headers.get(HttpResponseHeader.PAGINATION));
                if (paginationHeader !== null) {
                    const resp = new PaginatedResponseModel<T>();
                    resp.count = paginationHeader.totalCount;
                    resp.currentPage = paginationHeader.currentPage;
                    resp.pageSize = paginationHeader.pageSize;
                    resp.next = paginationHeader.nextPageLink;
                    resp.previous = paginationHeader.previousPageLink;
                    resp.results = response.body;
                    this.m_responseSubject.next(resp);
                } else {
                    this.m_notificationSvc.messageError(this.m_errTitle, "There was an error getting the pagination header.");
                }
            });
    }

    public send(request: IUrlRequest): Observable<PaginatedResponseModel<T>> {
        this.m_httpSubject.next(request);
        return this.response$;
    }
}