import { Component, ElementRef, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { AuthTokenService } from '@app/core/services';
import { AppConfigurationService, NotificationService, ContactModel, ContactEmailModel, CountryModel } from '@app/ultisat/models';
import { ContactService, CountryService } from '@app/ultisat/services';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ISelect2Selected, readSelect2TransportPaginationHeaders } from '@app/ultisat/utility';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'ph-contact-modal',
    templateUrl: './contact-modal.component.html',
})
export class ContactModalComponent implements OnInit, OnDestroy {
    private m_ngUnsubscribe = new Subject();
    @ViewChild('countrySelect', { read: ElementRef }) countrySelect2: ElementRef<any>;
    @ViewChild(ModalDirective) lgModal: ModalDirective;
    @Output() createdContact = new EventEmitter<ContactModel>();

    private m_isCountriesLoading = false;
    public loadingIndicator: boolean = true;

    public contact: ContactModel = new ContactModel();
    public contactEmail: ContactEmailModel = new ContactEmailModel();

    public rows;
    public count = 0;
    public reorderable: boolean = true;
    public isAdding = false;
    public editingRow: number | null = null;

    public controls: any = {
        pageSize: 10,
        filter: '',
        sort: ''
    }

    constructor(private m_contactSvc: ContactService
        , private m_countrySvc: CountryService
        , private m_appConfig: AppConfigurationService
        , private m_auth: AuthTokenService
        , private m_notifySvc: NotificationService
    ) { }

    public ngOnInit() {
        this.m_countrySvc.isCountriesLoading$.pipe(takeUntil(this.m_ngUnsubscribe)).subscribe(
            isLoading => {
                this.m_isCountriesLoading = isLoading; this.evalLoading()
            }
        );
    }

    public open(): void {
        this.resetForm();
        this.lgModal.show();
    }

    public close(): void {
        this.lgModal.hide();
    }

    private countryTransport(params, success, failure): void {
        let self = this;
        this.m_isCountriesLoading = true;
        this.evalLoading();
        const $request = $.ajax(params);
        $request.then(readSelect2TransportPaginationHeaders).then(success);
        $request.fail(failure);
        $request.always(() => {
            self.m_isCountriesLoading = false; self.evalLoading();
        });
        return $request;
    }
    public select2OptsCountry: any = {
        placeholder: "Select Country...",
        width: '100%',
        ajax: {
            url: `${this.m_appConfig.PHARAOH_API_BASE_URL}/Country/`,
            headers: {
                "Authorization": "Bearer " + this.m_auth.token
            },
            data: (params: any) => {
                const query = {
                    searchFilter: params.term,
                    page: params.page,
                    pageSize: 500
                }
                return query;
            },
            transport: this.countryTransport.bind(this)
        }
    };
    public onCountryChange($event: ISelect2Selected<CountryModel>): void {
        if ($event && $event.data) {
            this.contact.countryId = $event.data.id;
        }
    }

    public editRow(index: number) {
        this.contactEmail = { ...this.contact.emails[index] };
        if (this.editingRow != index) { this.editingRow = index } else { this.resetInputs() };
    }

    public updateRow(index: number) {
        this.contact.emails[index] = this.contactEmail;
        this.resetInputs();
    }

    public deleteRow(index: number) {
        this.contact.emails.splice(index, 1);
        this.resetInputs();
    }

    public resetInputs() {
        this.isAdding = false;
        this.editingRow = null;
        this.contactEmail = new ContactEmailModel();
        this.rows = [...this.contact.emails]
    }

    public save(): void {
        this.contact.emails.push(this.contactEmail);
        this.resetInputs();
    }

    public ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    private evalLoading(): void {
        this.loadingIndicator = this.m_isCountriesLoading;
    }

    resetForm() {
        const form = $('#contact-create-form');
        form.data('bootstrapValidator').resetForm();
    }

    public submitContact($event: Event) {
        $event.preventDefault();
        const form = $('#contact-create-form');
        form.data('bootstrapValidator').validate();
        if (form.data('bootstrapValidator').isValid()) {
            this.m_contactSvc.createContact(this.contact).pipe(first()).subscribe(response => {
                if (response) {
                    this.m_notifySvc.notifySuccess("Contact Created Successfully", `You successfully created ${this.contact.firstName}'s Contact`)
                    this.createdContact.emit(response);
                    this.contact = new ContactModel();
                    $('#countrySelect').val(null).trigger('change');
                    this.close();
                }
            });
        }
    }
}
