﻿
export interface INotificationsFormatters {
    translateTitle?: boolean;
    translateMessage?: boolean;
    titleFormatter?: (str: string) => string;
    messageFormatter?: (str: string) => string;
}

export interface INotificationsConfigurationOptions extends INotificationsFormatters {
    bgColor?: string;
    iconClass?: string;
    smallIconClass?: string;
    timeout?: number;
}

export interface INotificationsConfiguration {
    options?: INotificationsConfigurationOptions;
    unauthorizedEndpoints?: Array<string>;
    notFoundEndpoints?: Array<string>;
}

export interface IConfirmationResult {
    buttonPressed: string;
    inputValue: any;
}

export interface IConfirmationOptions extends INotificationsFormatters {
    buttonNames?: Array<string>;
    buttonColors?: Array<string>;
    inputType?: string;
    inputSelectOptions?: Array<string>;
    inputPlaceholderText?: string;
}

export interface INotificationService {
    notifyInfo(title: string, message: string, options?: INotificationsConfigurationOptions): void;
    notifySuccess(title: string, message: string, options?: INotificationsConfigurationOptions): void;
    notifyWarning(title: string, message: string, options?: INotificationsConfigurationOptions): void;
    notifyError(title: string, message: string, options?: INotificationsConfigurationOptions): void;

    messageInfo(title: string, message: string, options?: INotificationsConfigurationOptions): Promise<void>;
    messageSuccess(title: string, message: string, options?: INotificationsConfigurationOptions): Promise<void>;
    messageWarning(title: string, message: string, options?: INotificationsConfigurationOptions): Promise<void>;
    messageError(title: string, message: string, options?: INotificationsConfigurationOptions): Promise<void>;

    confirmation(title: string, message: string, options?: IConfirmationOptions): Promise<IConfirmationResult>;
}

export abstract class NotificationService implements INotificationService {
    abstract notifyInfo: (title: string, message: string, options?: INotificationsConfigurationOptions) => void;
    abstract notifySuccess: (title: string, message: string, options?: INotificationsConfigurationOptions) => void;
    abstract notifyWarning: (title: string, message: string, options?: INotificationsConfigurationOptions) => void;
    abstract notifyError: (title: string, message: string, options?: INotificationsConfigurationOptions) => void;
    abstract messageInfo: (title: string, message: string, options?: INotificationsConfigurationOptions) => Promise<void>;
    abstract messageSuccess: (title: string, message: string, options?: INotificationsConfigurationOptions) => Promise<void>;
    abstract messageWarning: (title: string, message: string, options?: INotificationsConfigurationOptions) => Promise<void>;
    abstract messageError: (title: string, message: string, options?: INotificationsConfigurationOptions) => Promise<void>;
    abstract confirmation: (title: string, message: string, options?: IConfirmationOptions) => Promise<IConfirmationResult>;
}