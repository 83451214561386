import { Directive, Input, ElementRef, OnInit } from "@angular/core";

import * as Dropzone from "dropzone";
Dropzone.autoDiscover = false;
@Directive({
  selector: "[saDropzone]"
})
export class DropzoneDirective implements OnInit {
  @Input() saDropzone: any;

  private dropzone: any;

  constructor(private el: ElementRef) {
  }

  public ngOnInit(): void {
    this.initDropzone();
  }

  initDropzone() {
    this.dropzone = new Dropzone(this.el.nativeElement, this.saDropzone || {
      url: 'http://respondto.it/',
    });
  }
}
