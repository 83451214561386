import { HttpErrorResponse } from "@angular/common/http";
import { BulkResponseModel, IPaginatedRequestModel, NotificationService } from "../models";

export function getPaginatedRequestUrl(options?: IPaginatedRequestModel): string {
    let append = '';
    if (options) {
        if (typeof options.page !== 'undefined') {
            append += `?page=${options.page}`;
        }
        if (typeof options.pageSize !== 'undefined') {
            append += `${append === '' ? '?' : '&'}pageSize=${options.pageSize}`;
        }
        // if (typeof options.order !== 'undefined') {
        //     append += `${append === '' ? '?' : '&'}order=${options.order}`;
        // }
        if (typeof options.sort !== 'undefined') {
            append += `${append === '' ? '?' : '&'}sortField=${options.sort}`;
        }
        if (typeof options.search !== 'undefined') {
            append += `${append === '' ? '?' : '&'}searchFilter=${options.search}`;
        }
    }
    return append;
}

function processObjectError(error, maxDepth: number = 1): string {
    let msg = '';
    for (const iter in error) {
        if (error.hasOwnProperty(iter)) {
            if (Array.isArray(error[iter])) {
                msg += `${iter}: ${error[iter].join(" :: ")}</br>`;
            } else if (typeof error[iter] === 'object') {
                if (maxDepth > 0) {
                    msg += `${iter}.${processObjectError(error[iter], --maxDepth)}`;
                } else {
                    msg += JSON.stringify(error[iter]);
                }
            } else {
                msg += `${iter}: ${error[iter]}</br>`;
            }
        }
    }
    return msg;
}

export enum ApiErrorLevel {
    SUCCESS,
    INFO,
    WARNING,
    ERROR,
    CRITICAL
}

export interface IApiErrorMessage {
    level: ApiErrorLevel;
    message: string;
}

export function processErrorGetErrorMsg(defaultMsg: string, error: HttpErrorResponse): IApiErrorMessage {
    let msg = defaultMsg;
    if (error) {
        switch (error.status) {
            case 400:
            case 409:
                if (Array.isArray(error.error) && error.error.length > 0) {
                    msg = error.error.join("</br>");
                } else if (typeof error.error === 'object') {
                    msg = processObjectError(error.error);
                }
                break;
            case 401:
                if (null !== error.error && typeof error.error === 'object') {
                    if (error.error.detail === "Invalid Token" || error.error.detail === "The Token is expired") {
                        //token expired
                        return {
                            level: ApiErrorLevel.WARNING
                            , message: "Session Expired"
                        };
                    } else {
                        msg = processObjectError(error.error);
                    }
                } else {
                    if (null != error.headers) {
                        const authHeader: string = error.headers.get("www-authenticate");
                        console.log(authHeader);
                        const isInvalid = authHeader.substring(0, 29);
                        console.log(isInvalid);
                        if (isInvalid === `Bearer error="invalid_token",`) {
                            return {
                                level: ApiErrorLevel.WARNING
                                , message: "Session Expired"
                            };
                        }
                    }
                }
                break;
            case 403:
                if (Array.isArray(error.error) && error.error.length > 0) {
                    msg = error.error.join("</br>");
                } else if (null !== error.error && typeof error.error === 'object') {
                    msg = processObjectError(error.error);
                } else {
                    msg = "You have insufficient privileges";
                }
                break;
        }
    }
    return {
        level: ApiErrorLevel.ERROR
        , message: msg
    }
}

export function processError(notifyService: NotificationService, msg: string, error: HttpErrorResponse, title: string = "Error"): void {
    const apiMessage = processErrorGetErrorMsg(msg, error);
    switch (apiMessage.level) {
        case ApiErrorLevel.WARNING:
            if (apiMessage.message === "Session Expired") {
                notifyService.notifyWarning("Session Expired", "Please login again.");
            } else {
                notifyService.messageWarning(title, apiMessage.message);
            }
            break;
        default:
            notifyService.messageError(title, apiMessage.message);
            break;
    }
}

export function processBulkErrors(notifyService: NotificationService, msg: string, response: Array<BulkResponseModel>, title: string = "Error"): void {
    let errMsg = '';
    for (const error of response) {
        errMsg += `<br />${error.item_num}: ${error.errors}`;
    }
    if (errMsg !== '') {
        notifyService.messageError(title, errMsg);
    }
}