import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { UserModel, AppConfigurationService, CreateUserResponse, DeleteUserResponse, GetUserResponse, GetListUsersResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchUserResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class UserService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createUser = new HttpRequestStream<UserModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newUser$ = this.m_createUser.response$;
    public isCreateUserLoading$ = this.m_createUser.isLoading$;

    private m_getUsers = new HttpPaginatedRequestStream<UserModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public users$ = this.m_getUsers.response$;
    public isUsersLoading$ = this.m_getUsers.isLoading$;

    private m_getUser = new HttpRequestStream<UserModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public user$ = this.m_getUser.response$;
    public isUserLoading$ = this.m_getUser.isLoading$;

    private m_patchUser = new HttpRequestStream<UserModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchUser$ = this.m_patchUser.response$;
    public isPatchUserLoading$ = this.m_patchUser.isLoading$;

    private m_updateUser = new HttpRequestStream<UserModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateUser$ = this.m_updateUser.response$;
    public isUpdateUserLoading$ = this.m_updateUser.isLoading$;

    private m_deleteUser = new HttpRequestStream<DeleteUserResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteUser$ = this.m_deleteUser.response$;
    public isDeleteUserLoading$ = this.m_deleteUser.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createUser(user: UserModel): Observable<CreateUserResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/User/`;
        return this.m_createUser.send({ url: url, method: HttpMethod.POST, body: user });
    }

    public getUsers(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListUsersResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/User/${getPaginatedRequestUrl(options)}`;
        return this.m_getUsers.send({ url: url, isPoll: isPoll });
    }

    public getUser(id: number, isPoll = false): Observable<GetUserResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/User/${id}/`;
        return this.m_getUser.send({ url: url, isPoll: isPoll });
    }

    public updateUser(id: number, obj: any): Observable<PatchUserResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/User/${id}/`;
        return this.m_updateUser.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public patchUser(id: number, obj: any): Observable<PatchUserResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/User/${id}/`;
        return this.m_patchUser.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteUser(id: number): Observable<DeleteUserResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/User/${id}/`;
        return this.m_deleteUser.send({ url: url, method: HttpMethod.PATCH });
    }
}
