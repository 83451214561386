import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { RouteGuardPharaohService } from "./ultisat/services";

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [RouteGuardPharaohService],
    children: [
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full"
      },
      {
        path: "home",
        loadChildren: "./features/home/home.module#HomeModule"
      },
      {
        path: "accounts",
        loadChildren: "./features/accounts/accounts.module#AccountsModule"
      },
      {
        path: "billing",
        loadChildren: "./features/billing/billing.module#BillingModule"
      },
      {
        path: "telecom",
        loadChildren: "./features/telecom/telecom.module#TelecomModule"
      },
      {
        path: "inventory",
        loadChildren: "./features/inventory/inventory.module#InventoryModule"
      },
      {
        path: "admin",
        loadChildren: "./features/admin/admin.module#AdminModule"
      },
      {
        path: "miscellaneous",
        loadChildren:
          "./features/miscellaneous/miscellaneous.module#MiscellaneousModule",
        data: { pageTitle: "Miscellaneous" }
      },
    ]
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: "./features/auth/auth.module#AuthModule"
  },
  { path: "**", redirectTo: "miscellaneous/error404" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
