import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { UsageModel, AppConfigurationService, CreateUsageResponse, DeleteUsageResponse, GetUsageResponse, GetListUsagesResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchUsageResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class UsageService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createUsage = new HttpRequestStream<UsageModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newUsage$ = this.m_createUsage.response$;
    public isCreateUsageLoading$ = this.m_createUsage.isLoading$;

    private m_getUnbilled0UsageByVendor = new HttpPaginatedRequestStream<UsageModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public unbilledUsagesByVendor$ = this.m_getUnbilled0UsageByVendor.response$;
    public isUnbilledUsagesByVendorLoading$ = this.m_getUnbilled0UsageByVendor.isLoading$;

    private m_getUsage = new HttpRequestStream<UsageModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public usage$ = this.m_getUsage.response$;
    public isUsageLoading$ = this.m_getUsage.isLoading$;

    private m_patchUsage = new HttpRequestStream<UsageModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchUsage$ = this.m_patchUsage.response$;
    public isPatchUsageLoading$ = this.m_patchUsage.isLoading$;

    private m_deleteUsage = new HttpRequestStream<DeleteUsageResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteUsage$ = this.m_deleteUsage.response$;
    public isDeleteUsageLoading$ = this.m_deleteUsage.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public getUnbilledUsagesByVendor(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListUsagesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Usage/Unbilled/Vendor/${getPaginatedRequestUrl(options)}`;
        return this.m_getUnbilled0UsageByVendor.send({ url: url, isPoll: isPoll });
    }

    public getUsage(id: number, isPoll = false): Observable<GetUsageResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Usage/${id}/`;
        return this.m_getUsage.send({ url: url, isPoll: isPoll });
    }

    public patchUsage(id: number, obj: any): Observable<PatchUsageResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Usage/${id}/`;
        return this.m_patchUsage.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteUsage(id: number): Observable<DeleteUsageResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Usage/${id}/`;
        return this.m_deleteUsage.send({ url: url, method: HttpMethod.PATCH });
    }
}
