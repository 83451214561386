import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";
import { UserModel } from "./user.model";

export enum JobStatus {
    /// <summary>
    /// A job whose state is unknown
    /// </summary>
    UNKNOWN = "UNKNOWN",

    /// <summary>
    /// A job that has been queued for completion, but has not yet started.
    /// </summary>
    PENDING = "PENDING",

    /// <summary>
    /// A job that has been started and is in the process of running.
    /// </summary>
    PROCESSING = "PROCESSING",

    /// <summary>
    /// A job that has finished processing and has ended.
    /// </summary>
    COMPLETED = "COMPLETED",

    /// <summary>
    /// A job that could not be completed due to an error.
    /// </summary>
    ERROR = "ERROR",

    /// <summary>
    /// A job has reached a point where it has not resolved and considered to be timed out.
    /// </summary>
    TIMEDOUT = "TIMEDOUT",

    /// <summary>
    /// A job whose state is invalid
    /// </summary>
    INVALID = "INVALID",
}

export class JobProgression {
    id: number;
    modifiedDate: Date;
    createdDate: Date;        
    jobInfoId: number;
    jobInfo: JobInfo;
    description: string;
    progress: number;
}

export class JobInfo {
    id: number;
    modifiedDate: Date;
    createdDate: Date;        
    description: string;
    status: JobStatus;
    userId: number;
    user: UserModel;
    ownerId: number;
    owner: Account;
    changes: Array<JobProgression>;
}

// export class GetListJobInfosResponse extends PaginatedResponseModel<JobInfo> { }
export class GetJobInfoResponse extends JobInfo { }
export class CreateJobInfoResponse extends JobInfo { }
export class PatchJobInfoResponse extends JobInfo { }
export class DeleteJobInfoResponse { } 