import { PaginatedResponseModel } from "../paginated-response.model";

export enum UsageType {
    UNKNOWN,
    VOICE,
    DATA,
    SMS,
    MMS,
    INVALID
}

export class UsageModel {
    id: number;
    processedFileId: number;
    lineNumberId: number;
    numberA: string;
    locationIdA: number;
    numberB: string;
    locationIdB: number;
    fileReference: string;
    accountId: number;
    ratePlanId: number;
    vendorId: number;
    amount: number;
}

export class GetListUsagesResponse extends PaginatedResponseModel<UsageModel> { }
export class GetUsageResponse extends UsageModel { } 
export class CreateUsageResponse extends UsageModel { } 
export class PatchUsageResponse extends UsageModel { } 
export class DeleteUsageResponse { } 