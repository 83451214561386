import { PaginatedResponseModel } from "../paginated-response.model";
import { ContactModel } from "./contact.model";
import { CountryModel } from "./country.model";

export class VendorContact {
    id: number;
    description: string;
    vendorId: number;
    vendor: VendorModel;
    contactId: number;
    contact: ContactModel;
}

export class VendorModel {
    id: number;
    name: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zipCode: string;
    countryId: number;
    country: CountryModel;
    phoneNumber: string;
    faxNumber: string;

    contacts: Array<VendorContact> = [];
}

export class GetListVendorsResponse extends PaginatedResponseModel<VendorModel> { }
export class GetVendorResponse extends VendorModel { } 
export class CreateVendorResponse extends VendorModel { } 
export class PatchVendorResponse extends VendorModel { } 
export class DeleteVendorResponse { } 