import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { AppConfigurationService, IPaginatedRequestModel, NotificationService, ReleaseModel } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";

@Injectable()
export class ReleaseNotesService implements OnDestroy {
    private m_ngUnsubscribe = new Subject();

    private m_getReleases = new HttpPaginatedRequestStream<ReleaseModel>(this.m_http, this.m_appConfigSvc, this.m_notificationSvc, this.m_ngUnsubscribe);
    public releases$ = this.m_getReleases.response$;
    public isReleasesLoading$ = this.m_getReleases.isLoading$;

    constructor(private m_http: HttpClient
        , private m_appConfigSvc: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) {        
    }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next()
        this.m_ngUnsubscribe.complete();
    }

    public getReleasesPage(options?: IPaginatedRequestModel, isPoll = false) {
        const url = `${this.m_appConfigSvc.PHARAOH_API_BASE_URL}/Release/${getPaginatedRequestUrl(options)}`;
        return this.m_getReleases.send({ url: url, isPoll: isPoll });
    }
}
