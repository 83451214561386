import { Injectable } from "@angular/core";
import { AppConfigurationService, AuthorizationService, UserCapabilityModel } from "@app/ultisat/models";
import { first } from "rxjs/operators";
import { CapabilitiesService } from "../api";

@Injectable()
export class AuthorizationPharaohService extends AuthorizationService {
    private m_isInitialized = false;
    private m_capabilities: string[] = [];

    constructor(
        private m_capabilitySvc: CapabilitiesService
        , private m_configSvc: AppConfigurationService
    ) { super(); }

    public isInitialized(): boolean {
        return this.m_isInitialized;
    }

    public initializePermissions(): Promise<any> {
        if (this.m_isInitialized) {
            return Promise.resolve(this.m_capabilities);
        }
        return new Promise((resolve, reject) => { 
            this.m_capabilitySvc.getCapabilities({ sort: "id", pageSize: 500 }).pipe(first()).subscribe(response => { 
                if (response && response.results && response.results.length > 0) {
                    this.m_capabilities = response.results.map((item: UserCapabilityModel) => item.name.toLowerCase());
                    this.m_isInitialized = true;
                } else {
                    this.m_capabilities = [];
                    this.m_isInitialized = false;
                }
                resolve(this.m_capabilities);
            }, error => { 
                this.m_isInitialized = false;
                reject(error);
            });
        });
    }

    hasCapability(capability: string): boolean {
        if (this.m_configSvc.authentication && this.m_configSvc.authentication.enabled) {
            return this.m_capabilities.indexOf(capability) >= 0;
        }
        return true;
    }
}