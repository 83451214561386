import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { UserRoleModel, AppConfigurationService, CreateUserRoleResponse, DeleteUserRoleResponse, GetUserRoleResponse, GetListUserRolesResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchUserRoleResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class UserRoleService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createUserRole = new HttpRequestStream<UserRoleModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newUserRole$ = this.m_createUserRole.response$;
    public isCreateUserRoleLoading$ = this.m_createUserRole.isLoading$;

    private m_getUserRoles = new HttpPaginatedRequestStream<UserRoleModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public userroles$ = this.m_getUserRoles.response$;
    public isUserRolesLoading$ = this.m_getUserRoles.isLoading$;

    private m_getUserRole = new HttpRequestStream<UserRoleModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public userrole$ = this.m_getUserRole.response$;
    public isUserRoleLoading$ = this.m_getUserRole.isLoading$;

    private m_patchUserRole = new HttpRequestStream<UserRoleModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchUserRole$ = this.m_patchUserRole.response$;
    public isPatchUserRoleLoading$ = this.m_patchUserRole.isLoading$;

    private m_updateUserRole = new HttpRequestStream<UserRoleModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateUserRole$ = this.m_updateUserRole.response$;
    public isUpdateUserRoleLoading$ = this.m_updateUserRole.isLoading$;

    private m_deleteUserRole = new HttpRequestStream<DeleteUserRoleResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteUserRole$ = this.m_deleteUserRole.response$;
    public isDeleteUserRoleLoading$ = this.m_deleteUserRole.isLoading$;

    private m_deleteUserRoles = new HttpRequestStream<DeleteUserRoleResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteUserRoles$ = this.m_deleteUserRoles.response$;
    public isDeleteUserRolesLoading$ = this.m_deleteUserRoles.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createUserRole(userrole: UserRoleModel): Observable<CreateUserRoleResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/UserRole/`;
        return this.m_createUserRole.send({ url: url, method: HttpMethod.POST, body: userrole });
    }

    public getUserRoles(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListUserRolesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/UserRole/${getPaginatedRequestUrl(options)}`;
        return this.m_getUserRoles.send({ url: url, isPoll: isPoll });
    }

    public getUserRole(id: number, isPoll = false): Observable<GetUserRoleResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/UserRole/${id}/`;
        return this.m_getUserRole.send({ url: url, isPoll: isPoll });
    }

    public updateUserRole(id: number, obj: any): Observable<PatchUserRoleResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/UserRole/${id}/`;
        return this.m_updateUserRole.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public patchUserRole(id: number, obj: any): Observable<PatchUserRoleResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/UserRole/${id}/`;
        return this.m_patchUserRole.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteUserRole(id: number): Observable<DeleteUserRoleResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/UserRole/${id}/`;
        return this.m_deleteUserRole.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteUserRoles(ids: Array<number>): Observable<DeleteUserRoleResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/UserRole/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteUserRoles.send({ url: url, method: HttpMethod.DELETE });
    }
}
