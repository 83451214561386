import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";
import { RatePlanModel } from "./rateplan.model";
import { ReferenceValueModel } from "./reference-value";
import { StockModel } from "./stock.model";
import { VendorModel } from "./vendor.model";

export class NumberState {
    id: number;
    createdDate: Date;
    modifiedDate: Date;

    numberId: number;

    accountId: number;
    account: Account;

    stockId: number;
    stock: StockModel;

    carrierVendorId: number;
    carrierVendor: VendorModel;

    ratePlanId: number;
    ratePlan: RatePlanModel;

    startDate: Date;
    endDate: Date;

    internalReferenceValues: Array<ReferenceValueModel>;
    externalReferenceValues: Array<ReferenceValueModel>;
}

export class NumberModel {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    digits: string;
    status: NumberStatus;
    identifier: string;

    stateHistory: Array<NumberState>;
}

export enum NumberStatus {
    UNKOWN
    , AVAILABLE
    , ASSIGNED
}

export class GetListNumbersResponse extends PaginatedResponseModel<NumberModel> { }
export class GetListNumbersByAccountResponse extends Array<NumberModel> { }
export class GetNumberResponse extends NumberModel { }
export class CreateNumberResponse extends NumberModel { }
export class PatchNumberResponse extends NumberModel { }
export class DeleteNumberResponse { }
export class BulkTerminateResponse {
    terminated: Array<NumberModel>;
    notFound: Array<string>;
    notAssigned: Array<string>;
}