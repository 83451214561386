﻿import { INotificationsConfiguration } from "./notification.service";

export interface IAppConfigurationService {
    API_THROTTLE: number;
    DEFAULT_POLL_RATE: number;
    PHARAOH_API_BASE_URL: string;
    notifications?: INotificationsConfiguration;
    authentication?: any;
}

export abstract class AppConfigurationService implements IAppConfigurationService{
    abstract API_THROTTLE: number;
    abstract DEFAULT_POLL_RATE: number;
    abstract PHARAOH_API_BASE_URL: string;
    abstract notifications?: INotificationsConfiguration;
    abstract authentication?: any;
}