import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';
import { CurrencyUSPipe } from './currency-us.pipe';
import { InvoiceChargeUsageSubtypePipe } from './invoice-charge-usage-subtype.pipe';
import { PaymentTypePipe } from './payment-type.pipe';

export const pipes = [
  CurrencyUSPipe,
  InvoiceChargeUsageSubtypePipe,
  PaymentTypePipe,
  TranslatePipe,
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [...pipes],
  exports: [...pipes]
})
export class PipesModule { }
