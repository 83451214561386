import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AppConfigurationService, ConfirmEndOfPeriod, HttpMethod, IPaginatedRequestModel, NotificationService, VerifyEndOfPeriod } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class EndOfPeriodService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_verifyEndOfPeriod = new HttpRequestStream<VerifyEndOfPeriod>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public verify$ = this.m_verifyEndOfPeriod.response$;
    public isVerifyEndOfPeriodLoading$ = this.m_verifyEndOfPeriod.isLoading$;

    private m_confirmEndOfPeriod = new HttpRequestStream<ConfirmEndOfPeriod>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public confirm$ = this.m_confirmEndOfPeriod.response$;
    public isConfirmEndOfPeriodLoading$ = this.m_confirmEndOfPeriod.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public verifyEndOfPeriod(period: string, isPoll = false): Observable<VerifyEndOfPeriod> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/EndOfPeriod/Verify/${period}/`;
        return this.m_verifyEndOfPeriod.send({ url: url, isPoll: isPoll });
    }

    public confirmEndOfPeriod(period: string, isPoll = false): Observable<ConfirmEndOfPeriod> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/EndOfPeriod/Confirm/${period}/`;
        return this.m_confirmEndOfPeriod.send({ url: url, isPoll: isPoll, method: HttpMethod.POST });
    }
}
