import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType, TranslationService } from '../models';

@Pipe({
    name: 'paymentType',
    pure: false
})
export class PaymentTypePipe implements PipeTransform {

    constructor(private m_translateSvc: TranslationService) { }

    transform(type: number, args?: any): any {
        switch (type) {
            case PaymentType.ACCOUNT_CREDIT:
                return this.m_translateSvc.getTranslation("Account Credit");
            case PaymentType.CHECK:
                return this.m_translateSvc.getTranslation("Check");
            case PaymentType.CREDIT_CARD:
                return this.m_translateSvc.getTranslation("Credit Card");
            case PaymentType.MANUAL_CREDIT_CARD:
                return this.m_translateSvc.getTranslation("Manual Credit Card");
            case PaymentType.WIRE_TRANSFER:
                return this.m_translateSvc.getTranslation("Wire Transfer");
            case PaymentType.UNKNOWN:
                return this.m_translateSvc.getTranslation("Unknown Payment Type");
            default:
                return this.m_translateSvc.getTranslation("Invalid Payment Type");
        }
    }
}
