import { Injectable, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActiveRouteDataService } from './active-route-data.service';
import { BrowserStorageService } from './abstract/base-storage.service';
import { IBehaviorSubjectDictionary, IStorageInitializeOptions, IStorageOptions } from '@app/ultisat/models';

@Injectable()
export class LocalStorageService extends BrowserStorageService implements OnDestroy {        
    private m_storage: IBehaviorSubjectDictionary<any> = {};    
    private m_keyPrefix: string = this.m_appName + "_" + this.m_appVersion + "_";

    constructor(
        activeRouteDataSvc: ActiveRouteDataService
        , @Inject(PLATFORM_ID) platformId: any
        , @Inject('LOCALSTORAGE') private m_localStorage: any
        , @Inject('SESSIONSTORAGE') private m_sessionStorage: any
    ) {
        super(activeRouteDataSvc);
        if (isPlatformBrowser(platformId)) {
            // localStorage will be available: we can use it.
            this.registerLocalStorage();
        }
        if (isPlatformServer(platformId)) {
            // localStorage will be null.
            this.registerLocalStorage();
        }        
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();        
    }

    private registerLocalStorage() {
        if (this.m_localStorage == null) {
            this.m_localStorage = {
                getItem: function (a: string): string | null { return null; }
                , setItem: function (a: string, b: string): void { }
                , removeItem: function (a: string): void { }
                , key: function (a: number): string | null { return null; }
                , length: 0
            }
        }
        if (this.m_sessionStorage == null) {
            this.m_sessionStorage = {
                getItem: function (a: string): string | null { return null; }
                , setItem: function (a: string, b: string): void { }
                , removeItem: function (a: string): void { }
                , key: function (a: number): string | null { return null; }
                , length: 0
            }
        }
    }

    /**
     * Gets the type of storage to use.
     * @param boolean sessionOnly
     * @returns
     */
    private getStorageType(sessionOnly: boolean = false): any {
        if (sessionOnly) {
            return this.m_sessionStorage;
        }
        return this.m_localStorage;
    }

    /**
     * Initializes the storage service with a version and an
     * application name for easy organization and retrival of data.
     * @param string applicationVersion
     * @param string applicationName
     */
    public initialize(options?: IStorageInitializeOptions): Observable<boolean> {
        this.m_initializingSubject.next(true);
        this.m_appName = options.applicationName;
        this.m_appVersion = options.applicationVersion;
        if (!this.m_appName || !this.m_appVersion) {
            console.warn("'applicationName' and 'applicationVersion' must be defined in initialize options!", options);
        }
        this.m_keyPrefix = `${this.m_appName}_${this.m_appVersion}_`;
        this.m_loadingSubject.next(true);
        const valid = this.m_localStorage.getItem(this.m_keyPrefix);
        this.m_loadingSubject.next(false);
        if (!valid) {
            //We did not find an old storage item
            // lets make sure this is a clean slate
            this.clear();
        }
        this.m_initializingSubject.next(false);
        this.m_readySubject.next(this.m_routeInfoSet && !this.m_initializingSubject.value);
        return this.initializing$;
    }

    /**
     * Gets the prefix to use to find the storage key
     * @param boolean useRoute Uses the current activated route for the key
     * @returns The prefix to use for this key
     */
    private getPrefix(useRoute: boolean = true): string {
        const prefix = this.m_keyPrefix;
        if (useRoute) {
            if (this.m_routeName) {
                return `${prefix}_${this.m_routeName}_`;
            } else if (this.m_routePageTitle) {
                return `${prefix}_${this.m_routePageTitle}_`;
            }
            return `${prefix}_route-unknown_`;
        }
        return prefix;
    }

    /**
     * Stores data by key
     * @param string key The key for this piece of data     
     * @param any value The data to store
     * @param boolean useRoute Uses the current activated route for the key
     */
    public store<T>(key: string, value: T, options?: IStorageOptions): Observable<boolean> {
        if (!this.m_readySubject.value) {
            console.warn(`StorageService was not initialized when trying to store key: '${key}'. Try calling initialize() in your app.component first.`, value);
        }
        this.m_storingSubject.next(true);
        const dataStr = JSON.stringify({ data: value });
        try {
            this.getStorageType(options ? options.sessionOnly : false).setItem(this.getPrefix(options ? options.useRoute : true) + key, dataStr);
        } catch (e) {
            console.error(`StorageService was unable to store '{${key}:${value}}'. The localStorage is full.`);
        }
        if (!this.m_storage.hasOwnProperty(key)) {
            this.m_storage[key] = new BehaviorSubject(value);
        } else {
            this.m_storage[key].next(value);
        }
        this.m_storingSubject.next(false);
        return this.storing$;
    }

    /**
     * Fetches data by key
     * @param string key The key for this piece of data
     * @param boolean useRoute Uses the current activated route for the key
     * @returns The data stored or null if key is not found
     */
    public fetch<T>(key: string, options?: IStorageOptions): Observable<T> {
        if (!this.m_readySubject.value) {
            console.warn(`StorageService was not initialized when trying to get key: '${key}'. Try calling initialize() in your app.component first.`);
        }
        this.m_loadingSubject.next(true);
        const storageType = this.getStorageType(options ? options.sessionOnly : false);
        const item = storageType.getItem(this.getPrefix(options ? options.useRoute : true) + key);
        let value = null;
        if (item) {
            value = JSON.parse(item).data;
        }
        if (!this.m_storage.hasOwnProperty(key)) {
            this.m_storage[key] = new BehaviorSubject<T>(value);
        } else {
            this.m_storage[key].next(value);
        }
        this.m_loadingSubject.next(false);
        return this.m_storage[key];
    }

    /**
     * Subscribes to a particular storage topic
     * @param key The key to subscribe to
     * @param options Options for defining the subscription
     */
    public subscribe<T>(key, options?: IStorageOptions): Observable<T> {
        if (!this.m_readySubject.value) {
            console.warn(`StorageService was not initialized when trying to subscribe to key: '${key}'. Try calling initialize() in your app.component first.`);
        }
        if (!this.m_storage.hasOwnProperty(key)) {
            this.m_storage[key] = new BehaviorSubject(null);
        }
        return this.m_storage[key];
    }


    /**
     * Removes data by key
     * @param string key The key for this piece of data
     * @param boolean useRoute Uses the current activated route for the key
     */
    public remove(key: string, options?: IStorageOptions): Observable<boolean> {
        if (!this.m_readySubject.value) {
            console.warn("StorageService was not initialized when trying to remove key: ", key);
        }
        this.m_storingSubject.next(true);
        this.getStorageType(options ? options.sessionOnly : false).removeItem(this.getPrefix(options ? options.useRoute : true) + key);
        if (this.m_storage.hasOwnProperty(key)) {
            this.m_storage[key].next(null);
        }
        this.m_storingSubject.next(false);
        return this.storing$;
    }

    /**
     * Clears all storage keys created with this service
     */
    public clear(options?: IStorageOptions): Observable<boolean> {
        // only removing items stored by this service
        this.m_storingSubject.next(true);
        const itemsToRemove = new Array<string>();
        const storageType = this.getStorageType(options ? options.sessionOnly : false);
        for (let iter = 0; iter < storageType.length; ++iter) {
            const key = storageType.key(iter);
            if (key && key.startsWith(this.m_appName + "_")) {
                itemsToRemove.push(key);
            }
        }
        itemsToRemove.forEach(key => storageType.removeItem(key));
        this.store('', this.m_keyPrefix, { useRoute: false });
        this.m_storingSubject.next(false);
        return this.storing$;
    }
}
