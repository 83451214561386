import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ProductClass, AppConfigurationService, CreateProductClassResponse, DeleteProductClassResponse, GetProductClassResponse, GetListProductClassesResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchProductClassResponse, OrderFulfillment } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class ProductClassesService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createProductClass = new HttpRequestStream<ProductClass>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newProductClass$ = this.m_createProductClass.response$;
    public isCreateProductClassLoading$ = this.m_createProductClass.isLoading$;

    private m_getProductClasses = new HttpPaginatedRequestStream<ProductClass>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public productClasses$ = this.m_getProductClasses.response$;
    public isProductClassesLoading$ = this.m_getProductClasses.isLoading$;

    private m_getProductClassItems = new HttpRequestStream<Array<ProductClass>>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public productClassItems$ = this.m_getProductClassItems.response$;
    public isProductClassItemsLoading$ = this.m_getProductClassItems.isLoading$;

    private m_getProductClass = new HttpRequestStream<ProductClass>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public productclass$ = this.m_getProductClass.response$;
    public isProductClassLoading$ = this.m_getProductClass.isLoading$;

    private m_patchProductClass = new HttpRequestStream<ProductClass>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchProductClass$ = this.m_patchProductClass.response$;
    public isPatchProductClassLoading$ = this.m_patchProductClass.isLoading$;

    private m_deleteProductClass = new HttpRequestStream<DeleteProductClassResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteProductClass$ = this.m_deleteProductClass.response$;
    public isDeleteProductClassLoading$ = this.m_deleteProductClass.isLoading$;

    private m_deleteProductClasses = new HttpRequestStream<DeleteProductClassResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteProductClasses$ = this.m_deleteProductClasses.response$;
    public isDeleteProductClassesLoading$ = this.m_deleteProductClasses.isLoading$;

    constructor(
        private m_http: HttpClient,
        private m_appConfig: AppConfigurationService,
        private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createProductClass(productclass: ProductClass): Observable<CreateProductClassResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProductClass/`;
        return this.m_createProductClass.send({ url: url, method: HttpMethod.POST, body: productclass });
    }

    public getProductClasses(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListProductClassesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProductClass/${getPaginatedRequestUrl(options)}`;
        return this.m_getProductClasses.send({ url: url, isPoll: isPoll });
    }

    public getProductClassItems(ids: Array<number>, isPoll = false): Observable<Array<ProductClass>> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProductClass/items/?${objectToQueryString({ ids: ids })}`;
        return this.m_getProductClassItems.send({ url: url, isPoll: isPoll });
    }

    public getProductClass(id: number, isPoll = false): Observable<GetProductClassResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProductClass/${id}/`;
        return this.m_getProductClass.send({ url: url, isPoll: isPoll });
    }

    public patchProductClass(id: number, obj: any): Observable<PatchProductClassResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProductClass/${id}/`;
        return this.m_patchProductClass.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public deleteProductClass(id: number): Observable<DeleteProductClassResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProductClass/${id}/`;
        return this.m_deleteProductClass.send({ url: url, method: HttpMethod.DELETE });
    }

    public deleteProductClasses(ids: Array<number>): Observable<DeleteProductClassResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/ProductClass/?${objectToQueryString({ ids: ids })}`;
        return this.m_deleteProductClasses.send({ url: url, method: HttpMethod.DELETE });
    }
}
