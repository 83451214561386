import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { PaymentModel, AppConfigurationService, CreatePaymentResponse, DeletePaymentResponse, GetPaymentResponse, GetListPaymentsResponse, HttpMethod, IPaginatedRequestModel, NotificationService, PatchPaymentResponse, GetListPaymentsByAccountResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl, objectToQueryString } from "@app/ultisat/utility";


@Injectable()
export class PaymentService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createPayment = new HttpRequestStream<PaymentModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newPayment$ = this.m_createPayment.response$;
    public isCreatePaymentLoading$ = this.m_createPayment.isLoading$;

    private m_getPayments = new HttpPaginatedRequestStream<PaymentModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public payments$ = this.m_getPayments.response$;
    public isPaymentsLoading$ = this.m_getPayments.isLoading$;

    private m_getPaymentsByAccount = new HttpPaginatedRequestStream<PaymentModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public paymentsByAccount$ = this.m_getPaymentsByAccount.response$;
    public isPaymentsByAccountLoading$ = this.m_getPaymentsByAccount.isLoading$;

    private m_getPayment = new HttpRequestStream<PaymentModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public payment$ = this.m_getPayment.response$;
    public isPaymentLoading$ = this.m_getPayment.isLoading$;

    private m_patchPayment = new HttpRequestStream<PaymentModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public patchPayment$ = this.m_patchPayment.response$;
    public isPatchPaymentLoading$ = this.m_patchPayment.isLoading$;

    private m_updatePayment = new HttpRequestStream<PaymentModel>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updatePayment$ = this.m_updatePayment.response$;
    public isUpdatePaymentLoading$ = this.m_updatePayment.isLoading$;

    private m_deletePayment = new HttpRequestStream<DeletePaymentResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deletePayment$ = this.m_deletePayment.response$;
    public isDeletePaymentLoading$ = this.m_deletePayment.isLoading$;

    private m_deletePayments = new HttpRequestStream<DeletePaymentResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deletePayments$ = this.m_deletePayments.response$;
    public isDeletePaymentsLoading$ = this.m_deletePayments.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createPayment(payment: PaymentModel): Observable<CreatePaymentResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Payment/`;
        return this.m_createPayment.send({ url: url, method: HttpMethod.POST, body: payment });
    }

    public getPayments(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListPaymentsResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Payment/${getPaginatedRequestUrl(options)}`;
        return this.m_getPayments.send({ url: url, isPoll: isPoll });
    }

    public getPaymentsByAccount(accountId: number, options?: IPaginatedRequestModel, isPoll = false): Observable<GetListPaymentsByAccountResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Account/${accountId}/Payments/${getPaginatedRequestUrl(options)}`;
        return this.m_getPaymentsByAccount.send({ url: url, isPoll: isPoll });
    }

    public getPayment(id: number, isPoll = false): Observable<GetPaymentResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Payment/${id}/`;
        return this.m_getPayment.send({ url: url, isPoll: isPoll });
    }

    public patchPayment(id: number, obj: any): Observable<PatchPaymentResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Payment/${id}/`;
        return this.m_patchPayment.send({ url: url, method: HttpMethod.PATCH, body: obj });
    }

    public updatePayment(id: number, obj: any): Observable<PatchPaymentResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Payment/${id}/`;
        return this.m_updatePayment.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public deletePayment(id: number): Observable<DeletePaymentResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Payment/${id}/`;
        return this.m_deletePayment.send({ url: url, method: HttpMethod.DELETE });
    }

    public deletePayments(ids: Array<number>): Observable<DeletePaymentResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/Payment/?${objectToQueryString({ ids: ids })}`;
        return this.m_deletePayments.send({ url: url, method: HttpMethod.DELETE });
    }
}
