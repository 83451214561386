import { Component, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from '@app/ultisat/models';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'ph-bulkTerm-modal',
    templateUrl: './bulkTermination-modal.component.html',
})
export class BulkTerminationModal implements OnChanges {
    @ViewChild(ModalDirective) lgModal: ModalDirective;
    @Input() terminatedNumbers: Array<string> = [];
    @Input() unassignedNumbers: Array<string> = [];
    @Input() notFoundNumbers: Array<string> = [];

    public showTerminated = false;
    public showNotFound = false;
    public showNotAssigned = false;
    public totalNumbers = 0;
    public visibleUnassigned: Array<string> = []
    public visibleNotFound: Array<string> = []
    public visibleTerminated: Array<string> = []

    constructor(private m_notifySvc: NotificationService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.terminatedNumbers || changes.unassignedNumbers || changes.notFoundNumbers) {
            this.totalNumbers = this.terminatedNumbers.length + this.unassignedNumbers.length + this.notFoundNumbers.length
            this.visibleUnassigned = this.unassignedNumbers.slice(0, 9)
            this.visibleNotFound = this.notFoundNumbers.slice(0, 9)
            this.visibleTerminated = this.terminatedNumbers.slice(0, 9)
        }
    }

    public open(): void {
        this.lgModal.show();
        this.showTerminated = false;
        this.showNotFound = false;
        this.showNotAssigned = false;
    }

    public close(): void {
        this.lgModal.hide();
    }

    public updateUnassigned(): void {
        this.showNotAssigned = !this.showNotAssigned
        if (this.showNotAssigned) {
            this.visibleUnassigned = this.unassignedNumbers;
        } else {
            this.visibleUnassigned = this.unassignedNumbers.slice(0, 9)
        }
    }

    public updateNotFound(): void {
        this.showNotFound = !this.showNotFound
        if (this.showNotFound) {
            this.visibleNotFound = this.notFoundNumbers;
        } else {
            this.visibleNotFound = this.notFoundNumbers.slice(0, 9)
        }
    }

    public updateTerminated(): void {
        this.showTerminated = !this.showTerminated
        if (this.showTerminated) {
            this.visibleTerminated = this.terminatedNumbers;
        } else {
            this.visibleTerminated = this.terminatedNumbers.slice(0, 9)
        }
    }

    public copy(numbers: Array<string>): void {
        const formattedNumbers = numbers.join('\n');

        (navigator as any).clipboard.writeText(formattedNumbers)
            .then(() => {
                this.m_notifySvc.notifySuccess("Copied to Clipboard", `You copied ${numbers.length} numbers.`)
            })
            .catch(err => {
                this.m_notifySvc.notifySuccess("Failed to Copy", `Failed to copy: ${err}`)
            });
    }
}
